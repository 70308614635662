import {Fragment, useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import useCallSecureApi from "../../../components/secureRequest";
import SearchParser from "../../../components/SearchParser";
import RosterGrid from "../../../components/RosterGrid";


const RosterTab = ({courseId, students, setStudents, staff, setStaff, studentSelectionModel, setStudentSelectionModel, staffSelectionModel, setStaffSelectionModel }) => {    
  const callSecureApi = useCallSecureApi();
    const [rosterFilter, setRosterFilter] = useState(() => (_) => true)
    const [tagGroups, setTagGroups] = useState([{tags: ["A", "B"], probabilities: [0.5]}])

    useEffect(() => {
      callSecureApi(`/course/${courseId}/tagGroup`).then(res => {
          setTagGroups(res)
      });
    }, [])

    const addUser = (user, isStudent) => {
      let postName, setUsers
      if (isStudent) {
        postName = "student"
        setUsers = setStudents
      } else {
        postName = "staff"
        setUsers = setStaff
      }
      callSecureApi(`/course/${courseId}/${postName}`, "POST", user)
      .then(res => {
        setUsers(oldUsers => {
          return [...oldUsers, res]
        })
      })
      .catch(err => {
        console.error(err)
      })
    }

    const handleTagsEditCommit = (user, isStudent) => {
      let postName, setUsers
      if (isStudent) {
        postName = "student"
        setUsers = setStudents
      } else {
        postName = "staff"
        setUsers = setStaff
      }
      callSecureApi(`/${postName}/${user._id}`, "PUT", user).then(res => {
        setUsers(oldUsers => {
            return [...oldUsers.filter(u => u._id !== res._id), res]
        })
      })
    }

    const createTagGroup = () => {
        setTagGroups(oldTagGroups => {
        const newTagGroups = [...oldTagGroups]
        const newTagGroup = {tags: [], probabilities: []}
        newTagGroups.push(newTagGroup)
        return newTagGroups
        })
    }

    return (
        <Fragment>
            <SearchParser elements={students} setFilter={setRosterFilter} default="{}"/>
            <RosterGrid
              users={
                students
                .map(s => ({...s, isStudent: true}))
                .concat(
                  staff
                  .map(s => ({...s, isStudent: false}))
                )
                .filter(rosterFilter)}
              selectionModel={studentSelectionModel.concat(staffSelectionModel)}
              onSelectionModelChange={(newSelectionModel) => {
                const studentIds = students.reduce((acc, x) => acc.add(x._id), new Set())
                let newStudentSelectionModel = []
                let newStaffSelectionModel = []
                for (const id of newSelectionModel) {
                  if (studentIds.has(id)) {
                    newStudentSelectionModel.push(id)
                  } else {
                    newStaffSelectionModel.push(id)
                  }
                }
                setStudentSelectionModel(newStudentSelectionModel)
                setStaffSelectionModel(newStaffSelectionModel)
              }}
              addUser={addUser}
              onTagsEditCommit={handleTagsEditCommit}
            />
            <br/>
            <h3>Tag Groups</h3>
            {tagGroups.map((tagGroup, i) => <TagGroupEditor
              tagGroup={tagGroup}
              onDelete={(j) => {
                setTagGroups(oldTagGroups => {
                  const newTagGroups = [...oldTagGroups]
                  newTagGroups[i].tags = oldTagGroups[i].tags.filter((_, k) => j !== k)
                  newTagGroups[i].probabilities.pop()
                  return newTagGroups
                })
              }}
              onAddTag={(newTag) => {
                setTagGroups(oldTagGroups => {
                  const newTagGroups = [...oldTagGroups]
                  if (!newTagGroups[i].tags.includes(newTag)) {
                    newTagGroups[i].tags.push(newTag)
                    if (newTagGroups[i].tags.length > 1) {
                      newTagGroups[i].probabilities.push(0)
                    }
                  }
                  return newTagGroups
                })
              }}
              onChangeProbability={(j, newProbability) => {
                setTagGroups(oldTagGroups => {
                  const newTagGroups = [...oldTagGroups]
                  newTagGroups[i].probabilities[j] = newProbability
                  return newTagGroups
                })
              }}
              onSave={() => {
                if (tagGroups[i]._id) {
                  callSecureApi(`/course/${courseId}/tagGroup/${tagGroups[i]._id}`, "PUT", {}, {tagGroup})
                    .then(_ => console.log(`Saved ${tagGroups[i]._id} successfully`))
                } else {
                  callSecureApi(`/course/${courseId}/tagGroup`, "POST", {}, {tagGroup})
                    .then(res => {
                      setTagGroups(oldTagGroups => {
                        const newTagGroups = [...oldTagGroups]
                        newTagGroups[i] = res
                        return newTagGroups
                      })
                      console.log(`Saved new ${res._id} successfully`)
                    })
                }
              }}
            />)}
            <Button onClick={createTagGroup}>Create New Tag Group</Button>
            <br/>
          </Fragment>
    )
}

function TagGroupEditor(props) {
    const [value, setValue] = useState("")
    const probabilities = props.tagGroup.tags.map((tag, i) =>
        i < props.tagGroup.probabilities.length ? props.tagGroup.probabilities[i] : 1 - sum(props.tagGroup.probabilities)
    )

    return (
        <div>
        <TextField
            InputProps={{
            startAdornment: props.tagGroup.tags.map((tag, i) => (
                <>
                <TextField
                    type="number"
                    size="small"
                    value={100 * probabilities[i]}
                    disabled={i === props.tagGroup.probabilities.length}
                    onChange={(event) => props.onChangeProbability(i, event.target.value / 100)}
                    InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                    error={0 > probabilities[i] || 100 < probabilities[i]}
                />
                <Chip
                    key={tag}
                    tabIndex={-1}
                    label={tag}
                    style={{margin: 5}}
                    onDelete={() => props.onDelete(i)}
                />
                <span style={{marginRight: 5, color: "rgba(0, 0, 0, 0.54)"}}>&bull;</span>
                </>
            )),
            value,
            onChange: (event) => {
                setValue(event.target.value)
            },
            onKeyDown: (event) => {
                if (event.key === "Enter" && value !== "") {
                props.onAddTag(value)
                setValue("")
                }
            }
            }}
            variant="outlined"
        />
        <Button onClick={props.onSave}>Save</Button>
        </div>
    )
}

function sum(arr) {
    return arr.reduce((x, b) => x + b, 0);
}

export default RosterTab;