import React, {Fragment, useEffect, useRef} from "react";
import "./ebbinghaus.css"

const htmlContent = String.raw`
  <div id="content">
    <div id="container">
      <canvas id="graph" width="1000" height="500" style="width:500px; height:250px;"></canvas>
      <div id="y_axis"></div>
      <div id="x_axis"></div>
      <div id="ui"></div>
    </div>
  </div>

  <div id="default_labels">
	<span id="ebbinghaus_y_axis">
		strength of memory &rarr;
	</span>
    <span id="ebbinghaus_x_axis">
		time &rarr;
	</span>
    <span id="ebbinghaus_decay">
		decay:
	</span>
    <span id="ebbinghaus_forgetting">
		sweet spot:
	</span>
    <span id="ebbinghaus_recall">
		timing of recall:
	</span>
    <span id="ebbinghaus_recalls">
		timing of recalls:
	</span>
    <span id="ebbinghaus_auto">
		auto-optimize!
	</span>
  </div>
  <script src="https://cdn.remember.school/ebbinghaus/ebbinghaus.js"></script>
  `

export default function Ebbinghaus() {
  const divRef = useRef(null)

  useEffect(() => {
    const slotHtml = document.createRange().createContextualFragment(htmlContent) // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = '' // Clear the container
    divRef.current.appendChild(slotHtml)
  }, [])

  return (
    <Fragment>
      <div id="nav-blocker" style={{height: 100, position: 'absolute', top: 0, left: 0, width: '100vw', backgroundColor: "#fff" }}/>
      <div className="comicWrapper" ref={divRef} style={{zIndex: 500}}/>
    </Fragment>
  )
}
