import { TextField } from "@material-ui/core";

function EnhancedTextField({
  title,
  blurb,
  label,
  id,
  defaultValue,
  setValue,
  placeholder = null,
  multiline = false,
}) {
  return (
    <>
      <h2>{title}</h2>
      <p>{blurb}</p>
      <TextField
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        id={id}
        autoComplete="off"
        margin="normal"
        variant="filled"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        multiline={multiline}
        fullWidth
      />
    </>
  );
}

export default EnhancedTextField;
