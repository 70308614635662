import Button from "@material-ui/core/Button";
import React, {Fragment, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import useCallSecureApi from "../components/secureRequest";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// TODO: Get course and set default value of canvasCourse to the value in the database
export default function UserSettings(props) {
  const callSecureApi = useCallSecureApi();

  const [courses, setCourses] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [canvasCourse, setCanvasCourse] = useState(null)

  const onChangeCourse = (e) => {
    setCanvasCourse(e.target.value)
    callSecureApi(`/course/${props.match.params.id}/set_canvas_course_id`, "PUT", {canvasCourseId: e.target.value})
  }

  useEffect(() => {
    callSecureApi(`/course/${props.match.params.id}/available_canvas_courses`).then(res => {
      console.log(res)
      setCourses(res)
      setIsLoading(false)
    })
  },[])

  const [token, setToken] = useState([])
  const handleChange = (event) => {
    setToken(event.target.value)
  }
  const handleClick = () => {
    callSecureApi('/user/canvas', "POST", {}, {"canvasApiToken": token}).then(responseData => {})
  }
  if (isLoading) {
    return <CircularProgress/>
  }

  if (courses.length > 0 && Object.keys(courses[0]).length > 0) {
    return (
      <FormControl>
        <Select
          value={canvasCourse}
          onChange={onChangeCourse}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {courses.map(course => (
            <MenuItem value={course.id}>{course.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  return (
    <Container>
      <br/>
      <br/>
      <br/>
      <br/>
      <h1>User Settings</h1>

      <h2>Canvas Authentication</h2>


      <Fragment>
        <TextField
          variant="outlined"
          placeholder="Input your access key"
          // name={key}
          fullWidth
          multiline
          value={token}
          onChange={handleChange}
        />
      </Fragment>
      <br /><br />
      <Button
        color="secondary"
        variant="contained"
        onClick={handleClick}
      >
        Authenticate Canvas
      </Button>

    </Container>
  );
}


// Use to implement OAuth2 once ready
// export default function getToken() {
//   async function postData("GET https://canvas.instructure.com/login/oauth2/auth?client_id=XXX&response_type=code&state=YYY&redirect_uri=https://example.com/oauth_complete", data = {}) {
//     // Default options are marked with *
//     const response = await fetch(url, {
//       method: 'POST', // *GET, POST, PUT, DELETE, etc.
//       mode: 'cors', // no-cors, *cors, same-origin
//       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//       credentials: 'same-origin', // include, *same-origin, omit
//       headers: {
//         'Content-Type': 'application/json'
//         // 'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       redirect: 'follow', // manual, *follow, error
//       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//       body: JSON.stringify(data) // body data type must match "Content-Type" header
//     });
//     return response.json(); // parses JSON response into native JavaScript objects
//   }
// }
