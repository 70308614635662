import React, {Fragment, useState} from "react";
import CardEditor from "../components/CardEditor";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import useCallSecureApi from "../components/secureRequest";
import {TagManager} from "../components/CardEditor/TagManager";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

// Store the passage + the sentence used to generate the text (highlight sentence in "show source")
// With show source, ask if seeing the source was helpful

// Might be worth using audio questions/answers

export default function NlpDemo(props) {
  const [text, setText] = useState("")
  const [cardList, setCardList] = useState([])
  const [tags, setTags] = useState([])
  const [apiUrl, setApiUrl] = useState("")

  async function accessGeneratorAPI(endpoint, data) {
    const response = await fetch(`${apiUrl}/${endpoint}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    });
    return response.json()
  }

  const generateCards = async (transcript) => {
    return accessGeneratorAPI("gen_cards", {transcript: transcript, link: window.location.href})
  }

  const callSecureApi = useCallSecureApi();

  function cardListObjectToCard(cardListObject) {
    return {
      script: String.raw`() => {
        return {
          question: "${cardListObject.question}", 
          answer: "${cardListObject.answer}" ,
          originalQuestion: "${cardListObject.question}",
          originalAnswer: "${cardListObject.answer}",
          source: "${text}"
        }
      }`
    }
  }

  const saveCard = (isGoodCard) => {
    if (cardList.length <= 0) {
      return
    }
    alert(`cardList[0]: ${cardList[0]}`)
    callSecureApi(`/card/${props.match.params.courseId}`, "POST", {},
      {
        ...cardListObjectToCard(cardList[0]),
        tags: [
          ...tags,
          "AUTO-GENERATED",
          isGoodCard ? " APPROVED" : " DISAPPROVED"
        ],
      }
    ).then(responseData => {
      cardList.shift()
      // console.log(responseData)
      // setId(responseData._id)
      // setSnackbarOpen(true)
    }).catch(err => {
      console.log(err)
      // setId(null)
      // setSnackbarOpen(true)
    })
  }

  const onClick = async () => {
    const newCards = await generateCards(text)
    // console.log("newCards: ", newCards)
    setCardList(newCards)
  }

  return (
    <div>
      {
        !apiUrl
          ? <Setup onClick={setApiUrl}/>
          : <Fragment>
            <CardEditor
              card={cardList.length > 0 ? cardListObjectToCard(cardList[0]) : {}}
              setCard={() => {
              }}
              saveCard={() => {
              }}
              input={
                <Fragment>
                  <TextField
                    variant="outlined"
                    placeholder="Add text to generate flashcards"
                    rows={16}
                    fullWidth
                    multiline
                    value={text}
                    onChange={event => setText(event.target.value)}
                  />
                  <br/>
                  <br/>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onClick}
                  >
                    Generate Cards
                  </Button>
                </Fragment>
              }
            />
            <br/>
            <Container>
              <Grid container>
                <Grid item xs={6}>
                  <TagManager tags={tags} onChange={setTags}/>
                </Grid>
                <Grid item xs={6} style={{textAlign: "center"}}>
                  <Button onClick={() => saveCard(true)}>
                    <ThumbUpIcon/>
                    Approve Card
                  </Button>
                  <Button onClick={() => saveCard(false)}>
                    <ThumbDownIcon/>
                    Disapprove Card
                  </Button>
                </Grid>
              </Grid>

            </Container>
          </Fragment>
      }
      <br/>
      <Container>
        {cardList.length > 0 ? <h3>Generated Questions</h3> : undefined}
        <List>
          {cardList.map((card, i) =>
            <ListItem button onClick={
              () => setCardList([cardList[i], ...cardList.filter(currCard => currCard !== card)])
            }>
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="textSecondary">Question: </Typography>
                  {card.question}
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">Answer: </Typography>
                  {card.answer}<br/>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </List>
      </Container>
    </div>
  )
}

function Setup(props) {
  const [url, setUrl] = useState("")

  return (
    <div style={{marginTop: 200}}>
      <ol>
        <li style={{margin: 20}}>
          Go to <a href="https://colab.research.google.com/drive/1sOJQO35BmqZ2H_HFBe_UE7c5rF-vvD_f" target="_blank"
                   rel="noreferrer">
          this colab notebook
        </a>
        </li>
        <li style={{margin: 20}}>
          Run all cells
        </li>
        <li style={{margin: 20}}>
          Once all cells have run, scroll to the output of the last cell and copy the ngrok.io link here <br/>
          <TextField
            style={{width: 300}}
            variant="outlined"
            placeholder="http://1c74d14a56c9.ngrok.io"
            onChange={(event) => setUrl(event.target.value)}
          />
        </li>
        <li style={{margin: 20}}>
          Click this button to <Button
          variant="contained"
          disableElevation
          onClick={() => props.onClick(url)}
        >
          Start Card Generation
        </Button>
        </li>
      </ol>
    </div>
  )
}
