import React, {useEffect, useState} from "react";
import CardEditor from "../components/CardEditor";
import useCallSecureApi from "../components/secureRequest";

export default function EditCard(props) {
  const [card, setCard] = useState({
    script: '// This is a placeholder card because the card you requested could not be loaded\n' +
      '() => {\n' +
      '  return {question: \'q\', answer: \'a\'}\n' +
      '}',
    tags: ['some', 'fake', 'tags', 'here']
  })

  const callSecureApi = useCallSecureApi();
  useEffect(() => {
    if (props.match.params.id) {
      callSecureApi(`/card/${props.match.params.id}`).then(responseData => {
        setCard(responseData);
      });
    }
  },[props.match.params.id]);

  const saveCard = () => {
    callSecureApi(`/card/${props.match.params.id}`, "PUT", card).then(responseData => {
      setCard(responseData)
    })
  }

  return (
    <CardEditor
      card={card}
      setCard={setCard}
      saveCard={saveCard}
    />
  )
}
