import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";

const LogoutButton = (props) => {
    const { logout } = useAuth0();
    return (
        <Button className="navbar-button" {...props}
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            Log Out
        </Button>
    );
};

export default LogoutButton;