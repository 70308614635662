const wrappedFetch = async (authToken, endpoint, method="GET", params={}, body) => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const request = {
        method: method,
        headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
        }
    }
    if (body) {
        request.body = JSON.stringify(body)
    }

    let response = await fetch(
        `${serverUrl}` + endpoint + "?" + new URLSearchParams(params),
        request
    )
    return response
}

export default wrappedFetch