import React from "react";
import Container from "@material-ui/core/Container";

export default function PrivacyPolicy() {
  return (
    <Container>
      <br/>
      <br/>
      <br/>
      <br/>
      <h1>Privacy Policy for Martian</h1>

      <p>At Martian, accessible from learn.withmartian.com, one of our main priorities is the privacy of our visitors.
        This Privacy Policy document contains types of information that is collected and recorded by Martian and
        how we use it.</p>

      <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to
        contact us.</p>
      <br/>

      Please see our privacy policy at <a
      href="https://docs.google.com/document/d/1FQv-CkZVNjED6Qp6BaqIMg7-UauOqO9-cf3-sBs2YdA/edit?usp=sharing">this
      link.</a>
    </Container>
  );
}