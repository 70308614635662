import React, {useEffect, useState} from 'react';
import CardRenderer from "../components/CardRenderer";
import useCallSecureApi from "../components/secureRequest";


export default function RankCards(props) {
  const [cards, setCards] = useState([])
  const [index, setIndex] = useState(0)
  const [ranks, setRanks] = useState([])
  const callSecureApi = useCallSecureApi()

  useEffect(() => {
    callSecureApi(`/module/${props.match.params.moduleNumber}/cards/5`).then(res => {
      setCards(res)
    })
  }, [])

  useEffect(() => {
    setRanks(Array(cards.length).fill("undefined"))
  }, [cards])

  const handleChange = (i) => (e) => {
    setRanks(oldRanks => {
      let result = [...oldRanks]
      result[i] = e.target.value
      return result
    })
  }

  const toRankingFormat = (cardList, rankList) => {
    if (cardList.length !== rankList.length) {
      throw RangeError(`cardList (length: ${cardList.length}) and rankList (length: ${rankList.length}) must have same length!`)
    }
    let result = Array(5).fill(1).map(_ => [])
    for (const card in cardList) {
      const rank = parseInt(rankList[card]) - 1
      result[rank].push(cardList[card]._id)
    }
    return result
  }

  const submitRanking = () => {
    callSecureApi(`/ranking/`, "POST", {}, toRankingFormat(cards, ranks)).then(_ =>
        window.location = "/course-student/63983d9b44d93e5189ac2160"
      )
  }

  const sendRememberData = async (answer) => {
    const params = {
      answer: answer,
      remembered: true,
      isExtra: true,
      isUnsure: false,
      studentRating: 1
    }
    await callSecureApi(`/answer/${currCard._id}`, "PUT", params)
  }

  const currCard = cards[index]

  if (currCard) {
    return(
      <section id="textbook-content" className="daily-review-content">
        <div className="container">
          <div className="col-25 left">
            <a href={"/course-student/63983d9b44d93e5189ac2160"}>&lt; CIS 4210/5210 Fall 2022</a>
            <p>Module {props.match.params.moduleNumber} Practice</p>
            {/*<Link to={`/course-student/${courseId}`}><h4>&lt; {course.name}</h4></Link>*/}
            {/*<h4>{assignment && assignment.name != null ? assignment.name : ""}</h4>*/}
          </div>
          <div className="col-50">
            <CardRenderer
              card={currCard}
              onButtonClick={(answer, ..._) => {
                sendRememberData(answer)
              }}
              onNext={() => setIndex(oldIndex => oldIndex + 1)}
              hideFeedback nextOnly
            />
          </div>
          <div className="col-25 right">
            <p style={{visibility: "hidden"}}>.</p>
          </div>
          <div style={{"clear": "both"}}/>
        </div>
      </section>
    )
  }

  if (cards.length === 0) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <section>
      <div className="container">
        <div style={{float: "left", width: "100%"}}>
          <h1>Rank the {cards.length} questions you just saw</h1>
          <p style={{fontSize: 20}}>Please rank these questions from best (1) to worst ({cards.length}) in accordance with how helpful they were with your studying.</p>
          <div style={{display: "flex"}}>
            {cards.map((card, i) =>
            ranks[i] === "undefined" && <CardRanker card={card} rank={ranks[i]} setRank={handleChange(i)}/>
            )}
          </div>
          <div style={{display: "flex"}}>
            {[...Array(5).keys()].map(x => <div style={{flexGrow: "1", borderRight: "black 1px solid", textAlign: "center", minHeight: 200}}>
              <b>Rank {x + 1} {x === 0 ? "(Best)" : undefined}{x === 4 ? "(Worst)" : undefined}</b>
              <div style={{display: "flex", flexDirection: "column"}}>
                {cards.map((card, i) =>
                  ranks[i] === (x + 1).toString() && <CardRanker card={card} rank={ranks[i]} setRank={handleChange(i)}/>
                )}
              </div>
            </div>)}
          </div>
          <button
            disabled={ranks.some(rank => rank === "undefined")}
            className={"button"}
            onClick={submitRanking}
            style={{fontSize: "1.3em"}}
          >
            Submit ranking and continue
          </button>
          {ranks.some(rank => rank === "undefined") && "You must rank all questions to submit"}
        </div>
        <div style={{"clear": "both"}}/>
      </div>
    </section>
  )
}

function CardRanker({rank, setRank, card}) {
  return (
    <div>
      <label htmlFor={card._id}>Rank:</label>

      <select name={card._id} id={card._id} value={rank} onChange={setRank}>
        <option value={"undefined"}>_</option>
        <option value={1}>1 (Best)</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5 (Worst)</option>
      </select>
      <div style={{flexGrow: 1, border: "black 1px solid", margin: 10, padding: 10}}>
        <CardRenderer
          card={card}
          onButtonClick={() => {}}
          hideFeedback hideRight
          backOnly smallText
        />
      </div>
    </div>
  )
}
