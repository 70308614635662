import { useEffect, useState} from 'react'
import useCallSecureApi from "../../components/secureRequest"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import ConfirmationBox from '../../components/ConfirmationBox';

export default function ConnectCourse({ assignCourse }) {
    const callSecureApi = useCallSecureApi()
    const [courses, setCourses] = useState([])
    const [courseChoiceIndex, setCourseChoiceIndex] = useState(null)
    const [confirmationActive, setConfirmationActive] = useState(false)

    useEffect(() => {
        callSecureApi(`/all_courses`)
            .then(res => setCourses(res))
    }, [])

    const submit = async () => {
        assignCourse(courses[courseChoiceIndex])
    }

    return (
        <form style={{ maxWidth: "500px", margin: "20px auto" }}>
            <h1>Select the Martian course to assign to this course. </h1>
            <RadioGroup>
            {courses.map((course, i) => (
                <FormControlLabel 
                    key={course._id}
                    value={i.toString()} 
                    control={<Radio />} 
                    label={course.name}
                    onClick={e => setCourseChoiceIndex(e.target.value)}
                />
            ))}   
            </RadioGroup>
            <div style={{margin: "20px 0"}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => courseChoiceIndex !== null && setConfirmationActive(true)}
                >
                    Submit
                </Button>
                <ConfirmationBox 
                    active={confirmationActive}
                    title="Are you sure?"
                    text={`This will assign the Martian course "${courses[courseChoiceIndex]?.name}" to this course.`}
                    onCancel={() => setConfirmationActive(false)}
                    warningText={courses[courseChoiceIndex]?.ltiContextId && `This Martian course is already assigned to a different class. Confirming will disconnect this Martian course from the other class.`}
                    onConfirm={submit}
                />
            </div>
        </form>
    )
}