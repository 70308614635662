import React, {Fragment, useEffect, useState} from "react";
import useCallSecureApi from "../secureRequest"
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const FieldBasedEditor = ({fields, scriptFrom, setCard, ...props}) => {
  const [state, setState] = useState({})

  const handleChange = (event) => {
    setState(
      (oldState) => {
        return ({...oldState,[event.target.name]: event.target.value})
      }
    )
  }

  useEffect(() => setCard( oldCard => {return {...oldCard, script: scriptFrom(state)}}), [state])

  return (
    <Fragment>
      {Object.keys(fields).map(key =>
        <Fragment>
          <TextField
            variant="outlined"
            placeholder={fields[key]}
            name={key}
            fullWidth
            multiline
            value={state[key]}
            onChange={handleChange}
          />
          <br />
          <br />
        </Fragment>
      )}
    </Fragment>
  )
}

const defaultFields = {
  question: "Input your question",
  answer: "Input the correct answer as shown to students",
  explanation: "Input an explanation for the correct answer with references to chapters of the textbook/notes"
}

const ShortAnswerEditor = (props) => <FieldBasedEditor
  fields={defaultFields}
  scriptFrom={state => `() => shortAnswer(\n  '${state.question}', \n  '**${state.answer}**<br/><br/>${state.explanation}'\n)`}
  {...props}
/>

const LongAnswerEditor = (props) => <FieldBasedEditor
  fields={defaultFields}
  scriptFrom={state => `() => longAnswer(\n  '${state.question}', \n  '**${state.answer}**<br/><br/>${state.explanation}'\n)`}
  {...props}
/>

const optionFields = {...defaultFields, answerChoices: "Input your answer choices separated by a newline for each answer"}

const outputMultipleQuestions = (choices) => {
  choices = choices == null ? "" : choices
  let questionList = choices.split("\n");
  let lineCount = 0;
  let stringOutput = "String.raw" + "`" + questionList[0] + "`,";
  lineCount = lineCount + 1;
  while (lineCount < questionList.length) {
    stringOutput = stringOutput + "String.raw" + "`" + questionList[lineCount] + "`,";
    lineCount = lineCount + 1;
  }
  return stringOutput;
}

const MultipleChoiceEditor = (props) => <FieldBasedEditor
  fields={optionFields}
  scriptFrom={state =>
    `() => multipleChoice(\n  '${state.question}', \n  [${outputMultipleQuestions(state.answerChoices)}], \n  '**${state.answer}**<br/><br/>${state.explanation}'\n)`}
  {...props}
/>

const CheckboxEditor = (props) => <FieldBasedEditor
  fields={optionFields}
  scriptFrom={state =>
    `() => checkBoxes(\n  '${state.question}', \n  [${outputMultipleQuestions(state.answerChoices)}], \n  '**${state.answer}**<br/><br/>${state.explanation}'\n)`}
  {...props}
/>

let clozeIndex = 1;
const ClozeEditor = (props) => {
  const [state, setState] = useState({})

  const handleClick = () => {
    const evalQuestion = state.question == null ? "" : state.question

    while (!evalQuestion.includes("{{c" + (clozeIndex - 1)) && clozeIndex > 1) {
      clozeIndex -= 1
      console.log(clozeIndex)
    }

    setState((oldState) => {
      return ({...oldState, question: evalQuestion + "{{c" + clozeIndex + "::}}"})
    })

    clozeIndex += 1
    console.log(clozeIndex)
  }

  const handleChange = (event) => {
    setState(
      (oldState) => {
        return ({...oldState,[event.target.name]: event.target.value})
      }
    )
  }
  useEffect(() => props.setCard(oldCard => {return {...oldCard, script : `() => clozeDeletion('${state.question}')`}}), [state])

  const fields = {
    question: "Input your question. Click the Cloze button to include cloze parameters like {{c1::example}}"
  }
  return (
    <Fragment>
      {Object.keys(fields).map(key =>
        <Fragment>
          <TextField
            variant="outlined"
            placeholder={fields[key]}
            name={key}
            fullWidth
            multiline
            value={state[key]}
            onChange={handleChange}
          />
          <br />
          <br />
        </Fragment>
      )}
      <Button
        color="secondary"
        variant="contained"
        onClick={handleClick}
      >
        Insert Cloze
      </Button>
      <br />
      <br />
    </Fragment>
  )
}



const AIGenerationEditor = ({setCard, ...props}) => {
  const [prompt, setPrompt] = useState("")
  const [generating, setGenerating] = useState(false)
  const [response, setResponse] = useState({})
  const callSecureApi = useCallSecureApi()

  const handleSubmission = (event) => {
    if (!prompt) return
    setGenerating(true)
    callSecureApi(`/question/write`, "POST", {}, {
      instructions: prompt
    }).then(setResponse)
    .catch(console.log)
    .finally(() => setGenerating(false))
  }

  useEffect(() => {
    setCard( oldCard => {
      return {...oldCard, 
        script: `() => longAnswer(String.raw\`${response.question}\`, String.raw\`${response.answer}\`)`}
    })
  }, [response])

  return (
    // <Fragment>
      <Fragment>
          <TextField
            variant="outlined"
            placeholder="Ex: 
            Slicing a python list. Include code.
            "
            name={"prompt"}
            rows={5}
            fullWidth
            multiline
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <Button
            style={{marginTop: 5}}
            color="secondary"
            variant="contained"
            onClick={handleSubmission}
            disabled={generating}
          >
            {generating ? "Generating..." : "Generate Question"}
          </Button>
          <br />
      </Fragment>
  )
}

export {ShortAnswerEditor, LongAnswerEditor, MultipleChoiceEditor, CheckboxEditor, ClozeEditor, AIGenerationEditor}
