function getSearchString(search) {
  return search.split("?").at(-1)
}

function searchParamsFrom(query) {
  return toTerms(query).reduce(addParamFromTerm, {})
}

const toTerms = (query) => query.split('&')

function addParamFromTerm(dict, s) {
  const [key, value] = s.split('=')
  return {...dict, [key]: decodeURI(value)}
}

export function getSearchParams(search) {
  return searchParamsFrom(getSearchString(search))
}
