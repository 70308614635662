import React, {Fragment, useState} from "react";
import './styles.css';
import QuestionPreview from "./QuestionPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from '@fortawesome/free-solid-svg-icons'

export default function QuestionAnnotationInterface(
  {questions, setQuestions, file, onAccept, onReject, assignments, source, onSelect, onRewrite}
) {

  const removeQuestion = i => prevQuestions => prevQuestions.filter((_, j) => i !== j)
  const [questionsToShow, setQuestionsToShow] = useState(5)
  const handleClick = (_) => setQuestionsToShow(x => x + 5)
  const handleChange = (e) => {
    const newAssignment = e.target.value && e.target.value !== 'question-bank' ? e.target.value : undefined
    onSelect(newAssignment)
  }

  return (
    <Fragment>
      <div style={{
        "backgroundColor": "#eeeeee",
        paddingLeft: "2%",
        paddingTop: "2%",
        paddingBottom: "1%"
      }}>
        <h4 style={{fontSize: '2em', marginTop: -5, marginBottom: 20, overflow: "scroll"}}>Questions
          from {source.name}</h4>
        <h6 style={{fontSize: '20px', marginTop: -5, marginBottom: 20, color: "#666666"}}>Send accepted questions
          to &nbsp;
          <span className="custom-select">
            <select
              name="send-destination"
              id="send-destination"
              onChange={handleChange}
              value={source.assignment ? source.assignment : 'question-bank'}
            >
              <option value="question-bank">Question Bank &nbsp;</option>
              {assignments.map(assignment => <option key={assignment._id}
                                                     value={assignment._id}>{assignment.name}</option>)}
            </select>
            <FontAwesomeIcon icon={faCaretDown} style={{borderBottom: "3px solid #666666", position: 'relative', top: '5.3px'}}/>
          </span>
        </h6>
        {questions.slice(0, questionsToShow).map((question, i) =>
          <QuestionPreview
            key={question._id}
            card={question}
            onAccept={(...args) => {
              onAccept(...args)
              setQuestions(removeQuestion(i))
            }}
            onReject={(...args) => {
              onReject(...args)
              setQuestions(removeQuestion(i))
            }}
            onEdit={() => {
            }}
            onRewrite={onRewrite}
          />
        )}
        {questions.length - questionsToShow > 0
          ? <div>
            <div className="button" onClick={handleClick}><span style={{fontWeight: 900}}>+</span> More Questions</div>
            {questions.length - questionsToShow} more questions available
          </div>
          : undefined}

        {/* This will not be used in the static version, but will be used in the version which generates questions */}
        {/*{file ? <div className="loader"/> : undefined}*/}
      </div>
    </Fragment>
  )
}
