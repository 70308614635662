import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {Link} from "react-router-dom";
import MaterialLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React, {Fragment, useEffect, useState} from "react";
import CardGrid from "../components/CardGrid";
import QueueIcon from "@material-ui/icons/Queue";
import useCallSecureApi from "../components/secureRequest";

export default function MyCards() {
  const [cards, setCards] = useState([]);

  const callSecureApi = useCallSecureApi();

  useEffect(() => {
    callSecureApi("/card").then(res => {
      console.log("/card returned: ", res)
      setCards(res)
    });
  }, []);

  return (
    <Grid container style={{marginTop: 100}}>
      <Grid item xs={3} style={{padding: 50}}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/courses">
            <MaterialLink>
              Dashboard
            </MaterialLink>
          </Link>
          <Typography color="textPrimary">
            My Cards
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={9} style={{padding: 10, height: 'calc(100vh - 200px)'}}>
        <Fragment>
          <CardGrid
            cards={cards}
          />
        </Fragment>
        <Button
          variant="contained"
          color="primary"
          href={`/my_cards/create`}
          style={{marginTop: 10, marginRight: 10}}
        >
          <QueueIcon/>Create Cards
        </Button>
      </Grid>
    </Grid>
  );
}
