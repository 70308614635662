import { useEffect, useState} from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import wrappedFetch from '../../utilities/wrappedFetch';
import ConfirmationBox from '../../components/ConfirmationBox';

export default function AssignmentSelection({ courseId, courseName, assignments, unassignCourse }) {
    const [assignment, setAssignment] = useState(null)
    const urlParams = new URLSearchParams(window.location.search);
    const [form, setForm] = useState(null)
    const [confirmationActive, setConfirmationActive] = useState(false)

    const submit = async () => {
        if (!assignment) return

        const ltiToken = urlParams.get('ltik')
        wrappedFetch(ltiToken, `/lti/deeplink`, "POST", {}, {
            name: assignment.name,
            courseId,
            assignmentId: assignment._id
        })
            .then(res => res.text())
            .then(form => setForm(form))
    }

    useEffect(() => {
        if (form) {
            const formElement = document.getElementById("ltijs_submit")
            formElement.submit()
        }
    }, [form])

    // handles final deeplinking redirect
    if (form) return <div dangerouslySetInnerHTML={{__html: form}} />

    return (
        <div style={{ maxWidth: "500px", margin: "20px auto" }}>
            <div style={{ right: "0px" }}>
                <Button 
                    variant="contained" 
                    onClick={() => setConfirmationActive(true)}>
                    Change Course
                </Button>
                <ConfirmationBox 
                    active={confirmationActive}
                    title="Are you sure?"
                    text={`This will unassign the Martian course "${courseName}" from this current course.`}
                    onCancel={() => setConfirmationActive(false)}
                    onConfirm={unassignCourse}
                />
            </div>
            {assignments.length === 0 ?
                <h1>No assignments found. </h1> :
                <form>
                    <h1>Select the assignment. </h1>
                    <RadioGroup>
                    {assignments.map(assignment => (
                        <FormControlLabel 
                            key={assignment._id}
                            value={assignment._id} 
                            control={<Radio />} 
                            label={assignment.name}
                            onClick={_ => setAssignment(assignment)}
                        />
                    ))}   
                    </RadioGroup>
                    <div style={{margin: "20px 0"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            }
        </div>
    )
}