import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import React, {Fragment, useState, useEffect} from "react";
import Typography from "@material-ui/core/Typography";

const COLUMNS_TO_FIELDS = {
  "Question": "question",
  "Tags": "tags",
  "Card Type": "script",
  "Source": "source",
  "Email": "email",
  "Type": "isStudent",
  "Has Signed Up": "user"
}

export default function SearchParser(props) {
  const defaultSearch = props.default ?? "{}"
  const [queryString, setQueryString] = useState(defaultSearch)
  useEffect(() => {
    props.setFilter(() => parse(JSON.parse(defaultSearch)))
  }, [])

  return (
    <Fragment>
      <Input
        id="outlined-basic"
        label="Search"
        variant="outlined"
        value={queryString}
        onChange={
          (event) => {
            setQueryString(event.target.value);
          }
        }
        onKeyPress={
          (event) => {
            if (event.key === 'Enter') {
              let value = event.target.value === "" ? props.default : event.target.value
              try {
                const result = JSON.parse(value)
                props.setFilter(() => parse(result))
              } catch (error) {
                alert(`invalid query: ${event.target.value}`)
              }
            }
          }
        }
        endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}
        style={{margin: 10, width: 'calc(100% - 20px)'}}
      />
        <a target="_blank" rel="noreferrer" style={{textDecoration: 'none', margin: 10}} href="https://docs.google.com/document/d/10kr9aiRvRV69nttY6Q8D1rHFlLt1UUvhia0EwqN5QI8/edit#heading=h.mu4hdovcdqkj">
          <Typography color="textSecondary" variant="overline">
            Query Lang documentation
          </Typography>
        </a>
    </Fragment>
  )
}

// A function to convert mongodb style queries into lambdas for filtering arrays
// Raw objects are interpreted as and
// The fields of the dict are interpreted as fields of the item being filtered
// The only exception to this is fields which start with a $, which are operators
// E.g. $or means that the operator used on the direct children of this field are or-ed instead of and-ed
// Each field in a dict should be a list of strings/objects. If it's a field, then the filtered thing should have that field
//  otherwise, the field contains an object, which returns a function
function parse(x) {
  if (typeof x === 'string') {
    return parseString(x)
  }
  if (Array.isArray(x)) {
    return parseAnd(x)
  }
  if (typeof x === 'object') {
    return parseObject(x)
  }
  throw new Error(`Cannot parse token: ${x}`)
}

function parseString(string) {
  return (x) => x && x.includes(string)
}

function parseNot(expr) {
  return (x) => !parse(expr)(x)
}

function parseOr(list) {
  return (x) => list.map(parse).some(p => p(x))
}

function parseAnd(list) {
  return (x) => list.map(parse).every(p => p(x))
}

function parseEntry([key, value]) {
  if (key.startsWith('$')) {
    switch (key) {
      case '$or':
        return parseOr(value)
      case '$not':
        return parseNot(value)
      default:
        throw new Error('Invalid operator in AST')
    }
  }
  return (x) => parse(value)(x[COLUMNS_TO_FIELDS[key]])
}

function parseObject(object) {
  return (x) => Object.entries(object).every(entry => parseEntry(entry)(x))
}
