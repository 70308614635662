import React from "react";
import Container from "@material-ui/core/Container";

export default function PrivacyPolicy() {
  return (
    <Container>
      <br/>
      <br/>
      <br/>
      <br/>
      <h1>Terms of Service for Martian</h1>

      <br/><br/>

      Please see our terms of service at <a
      href="https://docs.google.com/document/d/1urgM9OvCEeqrztmrlAnTx8IT-keLEnn5Xl3Joizc-bU/edit?usp=sharing">this
      link.</a>
    </Container>
  );
}