import { useState} from 'react'
import useCallSecureApi from "../../components/secureRequest"
import { Stepper, Step, StepButton, Button, Box, Tooltip, TextField } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

const copyableText = (text) => {
    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(text)
    }
    return (
        <Box 
        component="span" 
        sx={{
            cursor: 'pointer',
            padding: 2,
            borderRadius: 5, 
            backgroundColor: '#FFDAB9', 
            '&:hover': {
                backgroundColor: '#FFA07A',
            },
        }}
        onClick={handleCopyClick}> 
            <Tooltip title="Copy to clipboard">
                <span>&nbsp;{text}&nbsp;</span>
            </Tooltip>
        </Box>
    )
}

const AddMartianToCanvasPage = () => {
  return (
    <div>
        <h2>Add Martian to Canvas</h2>
        <Alert severity="warning" sx={{ mb: 2 }}>
          To create a Developer Key in Canvas, you must be an administrator for your institution's Canvas account.
        </Alert>
        <ol style={{lineHeight: 2}}>
            <li>
                Open <strong> Admin </strong> in the left navigation and click your administrator account.
            </li>
            <li>
                Select <strong> Developer Keys </strong> from the menu on the left.
            </li>
            <li>
                Click the <strong> + Developer Key </strong> dropdown menu in the top right corner and select <strong> + LTI Key </strong>.
            </li>
            <li>
                Put {copyableText("Martian LTI Key")} for the <strong>Key Name</strong>.
            </li>
            <li>
                Select the <strong>Configure Method</strong> as <strong>Enter URL</strong>.
            </li>
            <li>
                Put {copyableText("https://learn.withmartian.com/lti/config.json")} for the <strong>Config URL</strong>.
            </li>
            {/* <li>
                If that doesn't work, then switch the <strong>Configure Method</strong> to <strong>Paste JSON</strong>.
            </li>
            <li>
                Go to {copyableText("https://learn.withmartian.com/lti/config.json")} in your browser and copy/paste the contents into the Canvas textbox.
            </li> */}
            <li>
                Click <strong>Save</strong>.
            </li>
            <li>
                Switch the state of the key to <strong>On</strong>.
            </li>
        </ol>
    </div>
  )
}

const AddCanvasToMartianPage = ({data, setData}) => {
  return (
    <div>
        <h2>Add Canvas to Martian</h2>
        <ol style={{lineHeight: 2}}>
            <li>
                Copy the <strong>Client Id</strong> from Canvas and paste it below. It will be located under the <strong>Details</strong> tab.
                <br />
                <TextField
                variant="outlined"
                size="small"
                value={data.clientId}
                placeholder="10000000000005"
                onChange={e => setData({...data, clientId: e.target.value})}
                error={!data.clientId}
                helperText="required"
                />
          </li>
            <li>
                Insert the base url of your Canvas instance below. 
                <br />
                <TextField
                variant="outlined"
                size="small"
                style={{width: 400}}
                value={data.url}
                placeholder="https://canvas.instructure.com"
                onChange={e => setData({...data, url: e.target.value})}
                error={!data.url}
                helperText="required"
                />
            </li>
        </ol>
    </div>
  )
}

const AddMartianToCoursePage = ({clientId}) => {
    return (
        <div>
            <h2>Add Martian To Course Page </h2>
            <ol style={{lineHeight: 2}}>
                <li>
                    Open the course you want to add Martian to.
                </li>
                <li>
                    Click the <strong>Settings</strong> button in the top right corner.
                </li>
                <li>
                    Click the <strong>Apps</strong> tab.
                </li>
                <li>
                    Click the <strong>View App Configurations</strong> button.
                </li>
                <li>
                    Click the <strong>+ App</strong> button.
                </li>
                <li>
                    Select the <strong>Configuration Type</strong> as <strong>By Client ID</strong> and paste in {copyableText(clientId)}.
                </li>
            </ol>
        </div>
    )
}

export default function CanvasPlatformRegistration () {
    const callSecureApi = useCallSecureApi()
    const [data, setData] = useState({})
    const [activeStep, setActiveStep] = useState(0)
    const [errorMessage, setErrorMessage] = useState("")

    const steps = [
        { label: 'Add Martian to Canvas', content: <AddMartianToCanvasPage /> },
        { label: 'Add Canvas to Martian', content: <AddCanvasToMartianPage data={data} setData={setData}/>},
        { label: 'Add Martian to Course', content: <AddMartianToCoursePage clientId={data?.clientId}/> },
    ]

    const handleBack = () => {
        setErrorMessage("")
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            callSecureApi('/canvas-lti', 'PUT', '', data)
            .then(response =>  {
                if (response.success) {
                    window.location.href = "/"
                } else {
                    setErrorMessage("An error occurred while updating the LTI platform configuration. Please double check the Client ID and URL, and contact a Martian administrator if the problem persists.")
                }
            })
            .catch(error => {
                console.error(error)
                setErrorMessage("An error occurred. Please try again later.")
            })
        } else {
            if (activeStep === 1 && (!data.clientId || !data.url)) return
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
    }

    return (
      <div style={{ marginTop: 100, padding: "10px 100px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepButton onClick={() => setActiveStep(index)}>
                {step.label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div style={{ paddingLeft: 50 }}>
          {steps[activeStep].content}
          {errorMessage && (
            <div>
              <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>Form Error</AlertTitle>
                {errorMessage}
              </Alert>
            </div>
          )}
          <div style={{ paddingLeft: 20, paddingTop: 10 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!activeStep}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    )
}