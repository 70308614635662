import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useCallSecureApi from "../components/secureRequest";
import StreakCounter from "../components/StreakCounter"
import JoinCourse from "../components/JoinCourse";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {CardActionArea} from "@material-ui/core";

export default function AllCourses() {
  const [courses, setCourses] = useState([]);
  const [reviewData, setReviewData] = useState([])
  const [enrolledCourses, setEnrolledCourses] = useState([])
  const [isInstructor, setIsInstructor] = useState(false)
  const callSecureApi = useCallSecureApi();

  useEffect(() => {
    document.body.style.backgroundColor = '#FFFFFF';
  }, []);

  useEffect(() => {
    callSecureApi("/all_courses").then(res => setCourses(res));
    callSecureApi("/review_data").then(res => setReviewData(res));
    callSecureApi("/enrolled_courses").then(res => setEnrolledCourses(res));
    callSecureApi("/user/is_instructor").then(res => setIsInstructor(res));
  }, []);

  return (
    <div>
      <div style={{backgroundColor: "#fff", minHeight: courses.length > 0 ? undefined : '100vh'}}>
        <Container maxWidth="md" style={{padding: 30, paddingTop: 100}}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <h1>Welcome to Martian!</h1>

              {courses.length > 0 && <Fragment>
                <h2>You're currently an instructor in: </h2>
                <ul>
                  {courses.map(course => (
                    <li>
                      <Link to={"/course/" + course._id}>
                        {course.name}
                      </Link>
                      <Typography color="textSecondary">{course.description}</Typography>
                      <br/>
                    </li>
                  ))}
                </ul>
              </Fragment>}
              
              {isInstructor && <Button size="medium" variant="outlined" color="primary" disableElevation
                      style={{fontSize: 15, borderRadius: 50, marginBottom: 20}}
                      href="/courses/create_course"
              >
                Create Course
              </Button>}

              <h2>You're currently enrolled in: </h2>
              {enrolledCourses.map(course => (
                <>
                  <Card variant="outlined">
                    <CardActionArea href={`/course-student/${course._id}`}>
                      <CardContent>
                        <Typography variant="h5">
                          {course.name}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          {course.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <br/>
                </>
              ))}
              {
                enrolledCourses.length === 0 &&
                <p>
                  Huh, it looks like you aren't currently enrolled in any courses. Contact your course instructor if
                  you think this is a mistake.
                </p>
              }
              <JoinCourse/>
              <br/>
              <br/>
              <Button size="large" variant="contained" color="primary" disableElevation
                      style={{fontSize: 20, borderRadius: 50, marginTop: 20, marginRight: 5}}
                      href="/daily_review"
              >
                Study All Courses
              </Button>
              <Button size="large" variant="contained" disableElevation
                      style={{fontSize: 20, borderRadius: 50, marginTop: 20}}
                      href="/my_cards"
              >
                My Cards
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link to={"/tutorial"}>
                <img src="https://cdn.remember.school/dashboard_to_comic.png" style={{width: "100%"}}/>
              </Link>
              <Typography>
                Have questions, comments, or feature requests?
                <br/>
                <b>Contact us:</b>
                &nbsp;<a href="mailto:yash@withmartian.com,etan@withmartian.com">yash@withmartian.com</a> or
                &nbsp;<a href="mailto:yash@withmartian.com,etan@withmartian.com">etan@withmartian.com</a>
              </Typography>
            </Grid>
          </Grid>
          <br/>
          <StreakCounter reviewData={reviewData}/>
        </Container>
      </div>
    </div>
  );
}

function getDaysStudied(reviewData) {
  const pastWeek = getDaysInPastWeek().map(dateToString)
  return reviewData.reduce((acc, reviewDatum) => pastWeek.includes(reviewDatum.date) ? acc + 1 : acc, 0)
}

function getDaysInPastWeek() {
  const lastSunday = getLastSunday()
  const today = new Date()
  let currentDay = lastSunday
  let pastWeek = []
  while (currentDay <= today) {
    pastWeek.push(currentDay)
    currentDay = new Date(currentDay.getTime());
    currentDay.setDate(currentDay.getDate() + 1)
  }
  return pastWeek
}

function getLastSunday() {
  let t = new Date();
  t.setDate(t.getDate() - t.getDay());
  return t;
}

function dateToString(dateObj) {
  const options = {
    year: '2-digit', month: '2-digit', day: '2-digit',
    timeZone: 'America/New_York',
  }
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options)
  return dateTimeFormat.format(dateObj)
}
