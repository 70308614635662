import React, {Fragment, useEffect, useState} from "react";
import CardEditor from "../components/CardEditor";
import useCallSecureApi from "../components/secureRequest";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {TagManager} from "../components/CardEditor/TagManager";
import {
  CheckboxEditor,
  ClozeEditor,
  LongAnswerEditor,
  MultipleChoiceEditor,
  ShortAnswerEditor,
  AIGenerationEditor
} from "../components/CardEditor/TypedEditors";
import useUtils from '../utilities'

export default function CreateCard(props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [id, setId] = useState(false)
  const [cardType, setCardType] = useState('')
  const [assignments, setAssignments] = useState([])
  const [selectedAssignment, setSelectedAssignment] = useState(null)

  const callSecureApi = useCallSecureApi()
  const utilities = useUtils()

  useEffect(() => {
    setSelectedAssignment(utilities.getSearchParams(props.location.search)['assignment'])
    callSecureApi(`/course/${props.match.params.id}/assignment/`).then(res => setAssignments(res))
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    setId(null)
  };

  const newShortAnswerTemplate = {
    // script: '() => shortAnswer(\n  ' + questionVal + ', \n' +
    //   answerOption[0] + answerOption[1] + answerOption[2] + '<b>' + correctAnswer + '</b>' + '<br/><br/>' +
    //   explanationVal + '\n' + ')',
    script: '() => {\n' +
      '    // Your Code Here\n' +
      '    return {\n' +
      '      question: "The front of the card",\n' +
      '      answer: "The back of the card"\n' +
      '    }\n' +
      '  }',
    tags: []
  }

  const [card, setCard] = useState(newShortAnswerTemplate);

  const saveCard = () => {
    callSecureApi(`/card/${props.match.params.id}`, "POST", {...(selectedAssignment != null) && {assignment: selectedAssignment}}, card).then(responseData => {
      const oldTags = card.tags
      // Reset the interface with a new card containing a blank template and the previous tags
      setCard({...newShortAnswerTemplate, tags: oldTags})
      setId(responseData._id)
      setSnackbarOpen(true)
    }).catch(err => {
      console.log(err)
      setId(null)
      setSnackbarOpen(true)
    })
  };

  const handleChange = (event) => {
    setCardType(event.target.value);
  }

  const setTags = (newTags) => {
    setCard({...card, tags: newTags})
  }

  const chooseComponent = () => {
    if (cardType === "Short Answer") {
      return <ShortAnswerEditor setCard={setCard}/>
    }
    if (cardType === "Long Answer") {
      return <LongAnswerEditor setCard={setCard}/>
    }
    if (cardType === "Multiple Choice") {
      return <MultipleChoiceEditor setCard={setCard}/>
    }
    if (cardType === "Checkbox") {
      return <CheckboxEditor setCard={setCard}/>
    }
    if (cardType === "Cloze") {
      return <ClozeEditor setCard={setCard}/>
    }
    if (cardType === "AI Generation") {
      return <AIGenerationEditor setCard={setCard}/>
    }
    return undefined
  }

  // TODO: If users put a quotation mark or apostrophe or backtick in a TypedEditor, that can cause bugs
  // TODO: Add these to the editor for cards which have already been created
  // TODO: Clear input fields onSave
  //  Do this by moving the save button into the `TypedEditor`s
  const inputComponent = chooseComponent()

  return (
    <Fragment>
      <Container style={{marginTop: 120, marginBottom: 20}}>
        <FormControl style={{minWidth: 160}}>
          <InputLabel id="template-label">Select Template</InputLabel>
          <Select
            labelId="template-label"
            value={cardType}
            onChange={handleChange}
          >
            <MenuItem value={"Short Answer"}>Short Answer</MenuItem>
            <MenuItem value={"Long Answer"}>Long Answer</MenuItem>
            <MenuItem value={"Multiple Choice"}>Multiple Choice</MenuItem>
            <MenuItem value={"Checkbox"}>Checkbox</MenuItem>
            <MenuItem value={"Cloze"}>Cloze</MenuItem>
            <MenuItem value={"Script"}>Script</MenuItem>
            <MenuItem value={"AI Generation"}>AI Generation</MenuItem>
          </Select>
        </FormControl>
      </Container>
      <CardEditor
        input={inputComponent &&
          <Fragment>
            {inputComponent}
            <a target="_blank" rel="noreferrer" style={{textDecoration: 'none', margin: 10}}
               href="https://www.supermemo.com/en/archives1990-2015/articles/20rules">
              <Typography color="textSecondary" variant="overline">
                Article on making good cards
              </Typography>
            </a> | <a target="_blank" rel="noreferrer" style={{textDecoration: 'none', margin: 10}}
                      href="https://docs.google.com/spreadsheets/d/11xZoseAKs6dAfjQaBQo1cTegHncUMrOTMCDmOfYOWOo/edit?usp=sharing">
            <Typography color="textSecondary" variant="overline">
              Ideas For Cards
            </Typography>
          </a>

            <br/>
            <TagManager tags={card.tags} onChange={setTags}/>
            <br/>
            <Button color="primary" variant="contained" onClick={saveCard}>Save</Button>
          </Fragment>
        }
        card={card}
        setCard={setCard}
        saveCard={saveCard}
      />
      <p style={{marginLeft: 22}}>
        Send question to
        <Select
          style={{marginLeft: 10}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedAssignment != null ? selectedAssignment : 'question-bank'}
          onChange={(e) => {
            setSelectedAssignment(e.target.value !== 'question-bank' ? e.target.value : null)
          }}
        >
          <MenuItem value='question-bank'>Question Bank</MenuItem>
          {assignments.map(a => <MenuItem value={a._id}>{a.name}</MenuItem>)}
        </Select>
      </p>
      {props.match.params.id
        ? <Container>
          <Link to={`/nlp_demo/${props.match.params.id}`} style={{textDecoration: "none"}}>
            <Button color="primary">
              <AutorenewIcon/>
              Switch to card generator
            </Button>
          </Link>
        </Container>
        : undefined
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          id ?
            `Card saved with id '${id}'`
            :
            "Failed to create card. Check your network connection"}
        action={
          <Fragment>
            {id ? (
              <Link to={'/edit_card/' + id} target="_blank" style={{textDecoration: 'none'}}>
                <Typography variant="button" color="secondary" size="small" onClick={handleClose}>
                  Show Card
                </Typography>
              </Link>
            ) : undefined}
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small"/>
            </IconButton>
          </Fragment>
        }
      />
    </Fragment>
  )
}
