import React, {Fragment, useState} from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Popover from "@material-ui/core/Popover";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useCallSecureApi from "./secureRequest";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from '@material-ui/icons/Close';
import {Divider} from "@material-ui/core";

export default function FeedbackButton(props) {
  // Feedback Popover Rendering State
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const [sent, setSent] = React.useState(false);
  const handleSend = () => setSent(true);
  const handleCloseSend = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSent(false);
    if (event !== null) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Fragment>
      <Tooltip title="Provide Feedback">
        <IconButton
          className="feedback-button"
          aria-label="Provide Feedback"
          style={{position: 'absolute', right: 0, top: -50, opacity: 0.5, transform: "scale(2,2)"}}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <FeedbackIcon/>
        </IconButton>
      </Tooltip>
      <Popover
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{padding: 20}} className="popoverContent">
          <FeedbackForm card={props.card} onSubmit={() => {
            handleClose();
            handleSend();
          }}/>
        </div>

        {props.cardHistory
          ? (
            <Fragment>
              <Divider/>
              <div style={{padding: 20}} className="popoverContent">
                <RemoveCard historyId={props.cardHistory._id}/>
              </div>
            </Fragment>
          )
          : undefined
        }
      </Popover>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={sent}
        autoHideDuration={3000}
        onClose={handleCloseSend}
        message="Feedback sent"
        action={
          <Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSend}>
              <CloseIcon fontSize="small"/>
            </IconButton>
          </Fragment>
        }
      />
    </Fragment>
  );
}

function FeedbackForm(props) {
  const [type, setType] = useState("card");
  const [feedback, setFeedback] = useState("");
  const callSecureApi = useCallSecureApi();
  const handleClick = (e) => {
    setType(e.target.value);
  }

  const handleFeedback = (e) => {
    setFeedback(e.target.value);
  }

  const sendFeedback = (e) => {
    callSecureApi("/feedback", "POST", {
      type: type,
      message: feedback,
      card: props.card ? props.card._id : undefined
    });
    props.onSubmit(e);
  }

  return (
    <Fragment>
      <FormControl component="fieldset">
        {props.card && <Fragment>
          <FormLabel component="legend">My Feedback is for...</FormLabel>
          <RadioGroup aria-label="type" name="type" value={type} onChange={handleClick}>
            <FormControlLabel value="card" control={<Radio/>} label="This Card"/>
            <FormControlLabel value="other " control={<Radio/>} label="Something Else"/>
          </RadioGroup>
        </Fragment>}
        <FormLabel component="legend">Feedback</FormLabel>
        <TextField
          id="outlined-multiline-flexible"
          placeholder="(E.g. The Card might be better if..., Could you try making..., I don't think you cover [some concept]...)"
          multiline
          value={feedback}
          onChange={handleFeedback}
          variant="outlined"
        />
        <br/>
        <Button color="primary" variant="outlined" onClick={sendFeedback}>Submit Feedback</Button>
      </FormControl>
    </Fragment>
  );
}

function RemoveCard({historyId}) {
  const [removed, setRemoved] = useState(false)

  const callSecureApi = useCallSecureApi();

  const handleRemove = () => {
    setRemoved(true)
    callSecureApi(`/cardHistory/${historyId}/set_schedule/`, "PUT", {
      scheduleType: 'onetime'
    })
  }

  const handleUndo = () => {
    setRemoved(false)
    callSecureApi(`/cardHistory/${historyId}/set_schedule/`, "PUT", {
      scheduleType: 'simple_exponential'
    })
  }

  if (removed) {
    return (
      <Button variant="outlined" color="danger" onClick={handleUndo}>Re-add Card</Button>
    )
  }

  return (
    <Button variant="outlined" color="danger" onClick={handleRemove}>Remove Card</Button>
  )
}
