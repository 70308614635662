import {useAuth0} from "@auth0/auth0-react";
import wrappedFetch from "../utilities/wrappedFetch";

const useCallSecureApi = () => {
  const {getAccessTokenSilently} = useAuth0();
  return async (endpoint, method, params, body) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await wrappedFetch(token, endpoint, method, params, body);
      return response.json();
    } catch (error) {
      throw error;
    }
  }
};

export default useCallSecureApi;
