import React from "react";
import Button from "@material-ui/core/Button";

export default function StudentFeedback({feedbackStatus, setFeedbackStatus}) {

  const handleClick = (buttonNum) => {
    setFeedbackStatus(buttonNum);
  }

  return (
    <div id="feedbackPanel">
      <b>Let us know how helpful this card is for your studying.</b>
      <br/><br/>
      {["Really good", "Acceptable", "Not helpful"].map((buttonText, i) =>
        <Button color="#000000" variant={feedbackStatus === i ? "contained" : "outlined"}
                onClick={() => handleClick(i)}>{buttonText}</Button>
      )}
    </div>
  )
}
