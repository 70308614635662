import React, {useEffect, useState} from 'react';
import CardRenderer from "../components/CardRenderer";
import Typography from "@material-ui/core/Typography";
import useCallSecureApi from "../components/secureRequest";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Confetti from 'react-confetti'
import Card from "@material-ui/core/Card";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import IconButton from "@material-ui/core/IconButton";
import aStarExample from "../images/a-star-search-algorithm-2.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from '@fortawesome/free-solid-svg-icons'


export default function DailyReview(props) {
  const [histories, setHistories] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardsNeedSorting, setCardsNeedSorting] = useState(false)
  const [isExtra, setIsExtra] = useState(false)
  const [index, setIndex] = useState(0)

  const courseId = props.match.params.courseId
  const assignmentId = props.match.params.assignmentId

  const currCard = cards[index]
  const getHistoryFor = getHistoryFrom(histories)
  const cardsWithoutHistories = cards.filter(card => getHistoryFor(card) == null)
  const numCardsRemaining = assignmentId == null ? cards.length - index : cardsWithoutHistories.length
  const currHistory = getHistoryFor(currCard)
  const timesSeen = currHistory ? currHistory.reviews.length : 0
  const [course, setCourse] = useState({})

  const queryParams = new URLSearchParams(window.location.search)

  let assignment;

  if (course.assignments != null && assignmentId != null) {
    assignment = course.assignments.find(assignment => assignment._id === assignmentId)
    console.log("assignment", assignment)
  }

  const callSecureApi = useCallSecureApi();

  useEffect(() => {
    document.body.style.backgroundColor = '#FFFFFF';
    let dataEndpoint = "/all_cards"
    if (courseId) {
      dataEndpoint = `/course/${courseId}/all_cards`
      callSecureApi(`/course-student/${courseId}/`).then(res => setCourse(res))
    }
    if (assignmentId) {
      dataEndpoint = `/assignment/${assignmentId}/all_cards`
    }
    callSecureApi(dataEndpoint).then(responseData => {
      setHistories(responseData.histories)
      setCards(responseData.cards)
      setCardsNeedSorting(assignmentId == null)
    });
  }, []);

  useEffect(() => {
    if (assignmentId != null) {
      if (cardsWithoutHistories.length === 0) {
        setIndex(cards.length)
        return
      }
      const firstCardWithoutHistory = cardsWithoutHistories[0]
      const newIndex = cards.indexOf(firstCardWithoutHistory)
      if (newIndex !== -1) {
        setIndex(newIndex)
      }
    }
  }, [cards])

  const checkCompletion = async () => {
    console.log("In check Completion")
    if (courseId && assignmentId) {
      const success = await callSecureApi(`/course/${courseId}/assignment/${assignmentId}/check_completion`, "POST")
      console.log("success: ", success)
      if (success) {
        console.log("In success")
        window.location.href = `/course-student/${courseId}/quiz/${assignmentId}/select_cards`
        console.log("After success")
      }
    }
  }

  if (cardsNeedSorting) {
    setCards(oldCards => sortedCards(oldCards))
    setCardsNeedSorting(false)
  }

  const getMoreCards = () => {
    callSecureApi("/extra_cards").then(responseData => {
      setHistories(responseData.histories);
      setCards(responseData.cards);
      setIsExtra(true)
      setCardsNeedSorting(true)
    });
  }

  const popCard = () => {
    const c = currCard;
    if (index < cards.length) {
      setIndex(index => index + 1)
    }
    return c;
  };

  const prevCard = () => {
    const c = currCard;
    if (index > 0) {
      setIndex(index => index - 1)
    }
    return c;
  }

  const REQUIRED_CARDS = [
    "61ad9bedd6fb8fa928b91e8a", // intro comic
    "61ad8c02d6fb8fa928b91e84", // csat
    "61ad8e76d6fb8fa928b91e85", // remember.school vs reading quizzes
    "61ad9180d6fb8fa928b91e88", // learning
    "61ad92c6d6fb8fa928b91e89", // grade
    "61ad90e1d6fb8fa928b91e87", //classes
    "61ad905dd6fb8fa928b91e86", // TA
    "61ad9c2ed6fb8fa928b91e8b", // anything else
  ]
  const priorityOf = (card) => {
    if (REQUIRED_CARDS.includes('' + card._id)) {
      return REQUIRED_CARDS.indexOf('' + card._id)
    }
    return Infinity
  }
  const requiredCardsFirst = (cardArr) => {
    return [...cardArr].sort((c1, c2) => {
      return Math.sign(priorityOf(c1) - priorityOf(c2))
    })
  }
  const sortedByDate = (cardArr) => {
    return [...cardArr].sort((c1, c2) => {
      const h1 = getHistoryFor(c1)
      const h2 = getHistoryFor(c2)
      return new Date(h1.nextDate) - new Date(h2.nextDate)
    })
  }
  const sortedCards = (cardArr) => {
    const required = cardArr.filter(card => REQUIRED_CARDS.includes('' + card._id))
    const incomplete = cardArr.filter(card => {
      return !required.includes(card) && getHistoryFor(card).reviews.length === 0
    })
    const complete = cardArr.filter(card => !required.includes(card) && !incomplete.includes(card))
    return requiredCardsFirst(required).concat(sortedByDate(incomplete)).concat(sortedByDate(complete))
  }

  const pushCard = (card) => {
    setCards(prevCards => {
      const newCards = prevCards.slice(0);
      newCards.push(card);
      return newCards;
    })
  }

  const sendRememberData = async (answer, remembered, isUnsure = false, studentRating) => {
    const params = {
      answer: answer,
      remembered: remembered,
      isExtra: isExtra || assignmentId != null,
      isUnsure: isUnsure,
      studentRating: studentRating
    }
    if (currHistory) {
      params.cardId = currHistory.card
      await callSecureApi("/review", "POST", params)
    } else {
      const newHistory = await callSecureApi(`/answer/${currCard._id}`, "PUT", params)
      console.log("currCard._id: ", currCard._id)
      console.log("newHistory: ", newHistory)
      setHistories(oldHistories => [...oldHistories, newHistory])
    }
  };

  const [selectedAnswer, setSelectedAnswer] = useState(0)
  const correctAnswer = 1
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = () => {
    setIsSubmitted(() => {
      return true
    })
  }

  const handleSelect = (i) => {
    if (isSubmitted) return () => {
    }
    return () => setSelectedAnswer(i)
  }

  function getAssignmentOfCard() {
    console.log("currCard", currCard)
    if (course.assignments != null) {
      for (const courseAssignment of course.assignments) {
        console.log("courseAssignment.cards", courseAssignment.cards)
        for (const card of courseAssignment.cards) {
          if (currCard != null && card._id === currCard._id) {
            return courseAssignment.name
          }
        }
      }
    }
    return "introductory materials"
  }

  return (
    <div>
      {/*<div style={{position: "relative", height: "calc(100vh - 100px)", marginTop: 100}}>*/}
      {/*</div>*/}
      <section id="textbook-content" className="daily-review-content">
        <div className="container">
          <div className="col-25 left">
            {queryParams.get('isolated') == 'true' ? <h4>{course.name}</h4> :
            <Link to={`/course-student/${courseId}`}><h4>&lt; {course.name}</h4></Link>}
            <h4>{assignment && assignment.name != null ? assignment.name : ""}</h4>
          </div>
          <div className="col-50">
            {/*The background reading*/}
            {/*TODO Fillin proper ID*/}
            <div className="background-reading" style={{display: currCard && currCard._id === 8888 ? "block" : "none"}}>
              <p style={{"marginTop": "0"}}>To understand <i>A*</i>, let's look at how this algorithm operates.</p>
              <div className="highlight">
                <p>What <i>A*</i> Search Algorithm does is that at each step it picks the node according to a
                  value-<b>'f'</b> which is
                  a parameter equal to the sum of two other parameters – <b>‘g’</b> and <b>‘h’</b>. At each step it
                  picks
                  the node/cell
                  having the lowest <b>'f'</b>, and processes that node/cell.
                  <br/><br/>
                  We define <b>‘g’</b> and <b>‘h’</b> as simply as possible below
                  <br/><br/>
                  <b>g</b> = the movement cost to move from the starting point to a given square on the grid, following
                  the path
                  generated to get there.
                  <br/>
                  <b>h</b> = the estimated movement cost to move from that given square on the grid to the final
                  destination.
                  This is often referred to as the heuristic, which is nothing but a kind of smart guess. We really
                  don’t
                  know the actual distance until we find the path, because all sorts of things can be in the way
                  (walls, water, etc.). There can be many ways to calculate this ‘h’ which are discussed in the later
                  sections.

                </p>
              </div>
              <p>Let's look at an example. <br/><br/>
                <img src={aStarExample} alt="A* example"/>
              </p>
              <br/>
            </div>
            {((currCard) && (currCard._id === REQUIRED_CARDS[0])) ? <Confetti/> : undefined}
            {/*TODO: Change top to a calc to avoid jumping when flipping cards over*/}
            {/*The question*/}
            <div>
              {index < cards.length ?
                <>
                  <CardRenderer
                    card={currCard}
                    cardHistory={currHistory}
                    onButtonClick={(answer, remembered, isUnsure = false, studentRating) => {
                      sendRememberData(answer, remembered, isUnsure, studentRating);
                      if (!remembered && currCard && !assignmentId) {
                        pushCard(currCard);
                      }
                      popCard();
                    }}
                  />
                </>
                : <div style={{textAlign: "center"}}>
                  {assignmentId == null
                    ? (
                      <>
                        <Typography variant="body2">🎉 You finished your daily review! 🎉</Typography>
                        <br/>
                        <br/>
                        <Button variant={"contained"} onClick={getMoreCards}>Keep Reviewing</Button>
                        <span style={{marginLeft: 20}}/>
                        <Link to="/" style={{textDecoration: "none"}}>
                          <Button color="primary" variant={"contained"}>Return Home</Button>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">🎉 You
                          completed {cards.length - cardsWithoutHistories.length}/{cards.length} the questions!
                          🎉</Typography>
                        <br/><br/>
                        <Button color="primary" variant={"contained"} onClick={checkCompletion}>Submit
                          Assignment
                        </Button>
                      </>
                    )
                  }
                </div>
              }
            </div>
          </div>
          <div className="col-25 right">
            <div className="card-navigation">
              <h4 className="questions-remaining">Questions Remaining: <b>{numCardsRemaining}</b>
              </h4>
              {assignmentId != null && <CompletionIndicator
                index={index}
                cards={cards}
                cardHistories={histories}
                onClick={(_, i) => setIndex(i)}
                onClickLeft={prevCard}
                onClickRight={popCard}
              />}
            </div>
            <br/><br/>
            <div style={{"clear": "both"}}/>
          </div>
          <div style={{"clear": "both"}}/>
        </div>
      </section>
    </div>
  );
  // Need some way to choose and active card and to designate some cards as reviewed and some as unreviewed
}

function AnswerOption({question, isSelected, onSelect, isCorrect, isSubmitted}) {
  return (
    <button onClick={onSelect}
            className={"answer-option" + (isSelected ? " selected" : "") + (isSubmitted ? (isCorrect ? " correct" : " incorrect") : "")}
            style={{"marginRight": "3%"}}>
      <FontAwesomeIcon icon={faCheck} style={isSubmitted ? (isCorrect ? {
        display: "block",
        marginRight: "1em"
      } : {display: "none"}) : {display: "none"}}/>
      <div className="circle"/>
      {question}
    </button>
  )
}

function CompletionIndicator({index, onClickRight, onClickLeft, cards, cardHistories, onClick}) {
  const getHistoryFor = getHistoryFrom(cardHistories)
  const completed = cards.map(card => getHistoryFor(card) != null)
  const [questionSelectorIsShown, setQuestionSelectorIsShown] = useState(true)
  const toggleClick = () => setQuestionSelectorIsShown(oldToggleState => !oldToggleState)
  return (
    <div style={{position: "relative", zIndex: 10}}>
      <Card className="completion-indicator">
        <div style={{marginTop: 10}}>
          <IconButton size="small" onClick={onClickLeft}><ArrowLeftIcon/></IconButton>
          {index}/{cards.length}
          <IconButton size="small" onClick={onClickRight}><ArrowRightIcon/></IconButton>
        </div>
        <div style={{marginBottom: 10}}>
          {completed.map((b, i) => <div
            onClick={() => onClick(b, i)}
            id={`card-counter-${cards[i]._id}`}
            style={{
              display: 'inline-block',
              boxSizing: 'border-box',
              backgroundColor: (b ? '#ff563f' : '#e2e2e2'),
              border: index === i ? "solid 2px black" : undefined,
              margin: 2,
              width: 32,
              height: 18,
              cursor: 'pointer'
            }}
          />)}
        </div>
      </Card>
      <br/>
    </div>
  )
}

const getHistoryFrom = histories => card => card ? histories.find(h => h.card === card._id) : null

const isEmpty = (obj) => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
