import React, {Fragment, useState} from "react";
import useCallSecureApi from "../components/secureRequest";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CircularProgress} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import useUtils from "../utilities/";

export default function MaterialUpload(props) {
  const [files, setFiles] = useState([])
  const [prompt, setPrompt] = useState("")
  const [loading, setLoading] = useState(false)
  const callSecureApi = useCallSecureApi()
  const utilities = useUtils()
  const assignmentId = utilities.getSearchParams(window.location.search).assignment
  const {courseId} = props.match.params

  const addFile = file => setFiles(oldFiles => [...oldFiles, file])

  const handleClick = async () => {
    if (files.length > 0) {
      setLoading(true)
      await processFiles()
      setLoading(false)
      window.location = `/course/${courseId}/`
    }
  }

  const processFiles = async () => {
    const uploadLinks = await Promise.all(files.filter(isFile).map(upload))
    const allLinks = uploadLinks.map(removeSearchParams).concat(files.filter(isString))
    return Promise.all(allLinks.map(createSource))
  }
  const upload = async (file) => {
    return (await uploadWith(file, await getUploadUrl(file))).url
  }
  const getUploadUrl = async (file) => {
    const {signedUrl} = await callSecureApi(`/course/${courseId}/presigned_url`, 'POST', {}, {fileName: file.name, fileType: file.type})
    return signedUrl
  }
  const uploadWith = async (file, signedUrl) => {
    return fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      }
    })
  }
  const removeSearchParams = (url) => url.split("?")[0]
  const createSource = async (link) => {
    return callSecureApi(`/course/${courseId}/source`, "POST", {}, {
      link: link,
      name: getNameFrom(link),
      assignment: assignmentId,
      ... prompt && {prompt: prompt}
    })
  }
  const getNameFrom = (link) =>
    link.includes("cdn.remember.school") ? link.split("/").at(-1).split("?")[0] : link
  return (
    <Fragment>
      <div className="container"
           style={{"width": "80%", marginLeft: 'auto', marginRight: 'auto', position: 'relative', marginBottom: 0}}>
        <div style={{clear: 'both'}}/>
        <h1>What material would you like to generate questions from?</h1>
        <h3 style={{color: "#666666"}}>You can add more material later too.</h3>
        <div style={{backgroundColor: "#eeeeee", padding: 50, marginBottom: 15}}>
          <div className="col-50" style={{borderRight: "1px solid #000000", boxSizing: 'border-box'}}>
            <TextbookUploadButton setFile={addFile}/>
            <div style={{
              float: 'right',
              width: "55%",
              color: "#666666",
              fontWeight: 600,
            }}>
              Upload your lecture notes, existing assignments, textbook, or other reference materials, and we'll use
              them to generate questions. Acceptable file formats include text files, pdf, mp4, and code files (e.g.
              *.py or *.ipynb if python, or similar for other languages).
            </div>
            <div style={{clear: 'both'}}/>
          </div>
          <div className="col-50" style={{transform: "translate(0px, 20%)"}}>
            <LinkButton setFile={addFile}/>
            <div style={{color: "#666666", width: "95%", marginLeft: 'auto', marginRight: 'auto'}}>
              If it's a single source of material, like an article, arxiv paper, or YouTube video, the questions will be
              generated from that material. If it's an index of materials, for example, a course website, syllabus, or
              YouTube playlist, questions will be generated from all the relevant material.
            </div>
          </div>
          <div style={{clear: 'both'}}/>
        </div>
        {files.map((file, i) =>
          <div className="inputted-material">
            {isString(file) ? file : file.name}
            <span>
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => setFiles(oldFiles => oldFiles.filter((_, j) => j !== i))}
                className={"clickable"}
                style={{float: "right"}}
              />
            </span>
          </div>
        )}
        <div style={{textAlign: "center"}}>
          <TextField
            placeholder='(Optional) Enter a prompt to generate questions. The overall prompt is displayed on the right:'
            variant="outlined"
            rows={3}
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            multiline
            InputProps={{
              as: TextareaAutosize,
              rowsMin: 3
            }}
            style={{
              width: "45%",
              height: '100%',
              maxWidth: 500,
              margin: 10,
              textAlign: "left"
            }}
          />
          <TextField
            label="Prompt"
            variant="outlined"
            rows={3}
            value={
`${prompt === "" ? "..." : prompt}
#### CONTEXT ####
\${context}

##########
QUESTIONS AND ANSWERS (in "Q: A:" form) HERE:`
          }
            multiline
            disabled
            InputProps={{
              as: TextareaAutosize,
              readOnly: true,
              rowsMin: 3
            }}
            style={{
              width: "45%",
              maxWidth: 500,
              margin: 10,
              textAlign: "left"
            }}
          />
          
        </div>
      </div>
      <div
        className="button"
        style={{
          marginLeft: "auto",
          marginRight: 10,
          width: 100
        }}
        onClick={handleClick}
      >
        {loading ? <CircularProgress color="white" size={20}/> : "Continue"}
      </div>
    </Fragment>
  )
}

function TextbookUploadButton({setFile, ...props}) {
  const hiddenFileInput = React.useRef(null);
  const handleClick = _ => hiddenFileInput.current.click();
  const handleChange = event => event.target.files[0] ? setFile(event.target.files[0]) : undefined
  return (
    <Fragment>
      <div
          className="textbook-upload-button"
          style={{
            fontSize: 20,
            width: '40%',
            height: 300,
            boxSizing: 'border-box',
            padding: 30,
            float: 'left',
            marginRight: 5,
            fontWeight: 800,
            boxShadow: "10px 10px #ff563f",
            backgroundColor: "#FFFFFF",
            cursor: "pointer"
          }}
          onClick={handleClick}
      >
        Upload Your Files Here
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </Fragment>
  )
}

function LinkButton({setFile}) {
  const [value, setValue] = useState("")
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  const handleClick = () => {
    if (value) {
        setFile(value)
        setValue("")
    }
  }

  return (
    <>
      <input
        placeholder={"Add a link"}
        value={value}
        onChange={handleChange}
        className="textbook-upload-button"
        style={{
          fontSize: 20,
          width: '70%',
          boxSizing: 'border-box',
          paddingLeft: 15,
          padding: 5,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
          fontWeight: 800,
          border: 'solid 10px #000000',
          backgroundColor: "#FFFFFF",
          boxShadow: "10px 10px #ff563f",
        }}
      />
      <div
        className="button" onClick={handleClick}
        style={{
          width: 120,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >Add</div>
    </>
  )
}

function isString(s) {
  return typeof s === 'string'
}

function isFile(x) {
  return x instanceof File
}
