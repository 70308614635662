import React, { useEffect } from 'react'

/**
 * This component is a popup window that exists solely to allow the user to 
 * login when in an iframe (since auth0 will block the login popup if it is in 
 * an iframe). It will close itself after the user has logged in.
 */
export default function LoginPopup() {
    useEffect(() => {
        window.close()
    }, [])
    return (<></>)
}