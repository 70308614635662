import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import useCallSecureApi from "../components/secureRequest";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Redirect} from "react-router-dom";
import './Comic.css';
import FeedbackButton from "../components/feedback-button";

export default function DailyReview(props) {
  // TODO: Add a back button

  const courseNameImageURL = () => {
    if (props.match.params.id === "60ccc633c433c2118ff3120a") {
      return "https://cdn.remember.school/comic_screen_1_CIS545.png"
    }
    if (props.match.params.id === "61b66d5d39da0d35ca81deaf") {
      return "https://cdn.remember.school/comic_screen_1_EAS545.png"
    }
    if (props.match.params.id === "60a1ae54e2bdcf167a497f32") {
      return "https://cdn.remember.school/comic_screen_1_CIS521"
    } else {
      return "https://cdn.remember.school/comic_screen_1_default.png"
    }
  }

  const images = [
    "https://cdn.remember.school/comic_screen_1_CIS521.png",
    "https://cdn.remember.school/comic_screen_1_CIS545.png",
    "https://cdn.remember.school/comic_screen_1_EAS545.png",
    // TODO: This card is missing punctuation marks
    "https://cdn.remember.school/comic_screen_2_taking_classes.png",
    "https://cdn.remember.school/comic_screen_3_and_reviewing.png",
    "https://cdn.remember.school/comic_screen_4_what_if.png",
    // TODO: For 5, add context to the outfit. "There is actually a way to learn anything -- playing guitar, speaking french, mastering computer science --"
    "https://cdn.remember.school/comic_screen_5_there_is_actually.png",
    "https://cdn.remember.school/comic_screen_6_so_how_can.png",
    "https://cdn.remember.school/comic_screen_7_a_technique_first.png",
    "https://cdn.remember.school/comic_screen_8_in_1885_Hermann.png",
    "https://cdn.remember.school/comic_screen_9_he_found_that.png",
    "https://cdn.remember.school/comic_screen_10_philosophers_have_debated.png",
    "https://cdn.remember.school/comic_screen_11_heres_a_playable.png",
    "https://cdn.remember.school/comic_screen_12_how_fast_a_persons.png",
    "https://cdn.remember.school/comic_screen_13_but_in_general.png",
    "https://cdn.remember.school/comic_screen_14_although_when_you_stop.png",
    "https://cdn.remember.school/comic_screen_15_heres_the_simulation.png",
    "https://cdn.remember.school/comic_screen_16_is_there_a_better.png",
    "https://cdn.remember.school/comic_screen_17_to_understand_this.png",
    "https://cdn.remember.school/comic_screen_18_nor_one_thats_too_hard.png",
    "https://cdn.remember.school/comic_screen_19_the_sames_true.png",
    "https://cdn.remember.school/comic_screen_20_therefore_to_best.png",
    "https://cdn.remember.school/comic_screen_21_same_simulation_as_before.png",
    "https://cdn.remember.school/comic_screen_22_lets_say_youre_lazy.png",
    // TODO: For 23 and 24 replace this with a remember.school style card with question and answer
    "https://cdn.remember.school/comic_screen_23_should_you_have_evenly.png",
    "https://cdn.remember.school/comic_screen_24_give_it_your_best_guess.png",
    "https://cdn.remember.school/comic_screen_25_to_prove_this_isnt.png",
    "https://cdn.remember.school/comic_screen_26_why_must_the_gaps.png",
    "https://cdn.remember.school/comic_screen_27_but_you_know_whats_sweeter.png",
    "https://cdn.remember.school/comic_screen_28_and_this_is_why.png",
    "https://cdn.remember.school/comic_screen_29_we_have_gone.png",
    "https://cdn.remember.school/comic_screen_30_this_should_make.png",
    // TODO: Add a slide about how they can keep using this after the course as well to prepare for interviews and such
    "https://cdn.remember.school/comic_screen_31_just_one_card.png",
    "https://cdn.remember.school/comic_screen_32_hard_but_straightforward.png",
    "https://cdn.remember.school/comic_screen_33_when_do_you_want.png",
    "https://cdn.remember.school/comic_screen_34_for_example_you_could_play.png",
    // TODO: Between 34 and 35, introduce a date & time picker for when to email them
    // TODO: Make 35 have a more encouraging streak counter (higher number)
    "https://cdn.remember.school/comic_screen_35_underneath_the_handy_daily_review.png",
    "https://cdn.remember.school/comic_screen_36_we_etan_and_yash.png",
    "https://cdn.remember.school/comic_screen_37_if_you_have_any_ideas.png",
    "https://cdn.remember.school/comic_screen_38_our_emails_are.png",
    "https://cdn.remember.school/comic_screen_39_we_hope_have_fun.png"
  ]
  const [imageIndex, setImageIndex] = useState(0)
  const decrementIndex = () => setImageIndex(oldIndex => oldIndex - 1)
  const incrementIndex = () => setImageIndex(oldIndex => oldIndex + 1)

  useEffect(() => {
    const loadImage = imageUrl => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = imageUrl
        loadImg.onerror = err => reject(err)
      })
    }

    images.map(image => loadImage(image))
  }, [])

  const imageHTML = [
    <img className="comicImage" src={courseNameImageURL()}/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_2_taking_classes.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_3_and_reviewing.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_4_what_if.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_5_there_is_actually.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_6_so_how_can.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_7_a_technique_first.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_8_in_1885_Hermann.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_9_he_found_that.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_10_philosophers_have_debated.png"/>,
    <div className="comicAnimation">
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_11_heres_a_playable_top.png"/>
      <iframe will_source="/ebbinghaus?mode=0" scrolling="no" className="simZero"
              src="/ebbinghaus?mode=0" frameBorder="0"/>
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_11_as_you_can_see_bottom.png"/>
    </div>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_12_how_fast_a_persons.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_13_but_in_general.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_14_although_when_you_stop.png"/>,
    <div className="comicAnimation">
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_15_top_heres_the_simulation.png"/>
      <iframe will_source="/ebbinghaus?mode=1" scrolling="no" className="simOne"
              src="/ebbinghaus?mode=1" frameBorder="0"/>
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_15_bottom_a_single_recall_boosts.png"/>
    </div>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_16_is_there_a_better.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_17_to_understand_this.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_18_nor_one_thats_too_hard.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_19_the_sames_true.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_20_therefore_to_best.png"/>,
    <div className="comicAnimation">
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_21_top_same_simulation_as_before.png"/>
      <iframe will_source="/ebbinghaus?mode=2" scrolling="no" className="simOne"
              src="/ebbinghaus?mode=2" frameBorder="0"/>
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_21_bottom_see_if_you_time.png"/>
    </div>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_22_lets_say_youre_lazy.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_23_should_you_have_evenly.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_24_give_it_your_best_guess.png"
         id="btnLeft"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_24_with_increasing_gaps.png"
         id="btnLeft"/>,
    <div className="comicAnimation">
      <img className="comicImage"
           src="https://cdn.remember.school/comic_screen_25_which_is_very_counter_intuitive.png"/>
      <iframe will_source="/ebbinghaus?mode=3" scrolling="no" className="simTwo"
              src="/ebbinghaus?mode=3" frameBorder="0"/>
    </div>,
    <div className="comicAnimation">
      <img className="comicImage" src="https://cdn.remember.school/comic_screen_25_top_to_prove_this_isnt.png"/>
      <iframe will_source="/ebbinghaus?mode=4" scrolling="no" className="simThree"
              src="/ebbinghaus?mode=4" frameBorder="0"/>
    </div>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_26_why_must_the_gaps.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_27_but_you_know_whats_sweeter.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_28_and_this_is_why.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_29_we_have_gone.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_30_this_should_make.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_31_just_one_card.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_32_hard_but_straightforward.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_33_when_do_you_want.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_34_for_example_you_could_play.png"/>,
    <img className="comicImage"
         src="https://cdn.remember.school/comic_screen_35_underneath_the_handy_daily_review.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_36_we_etan_and_yash.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_37_if_you_have_any_ideas.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_38_our_emails_are.png"/>,
    <img className="comicImage" src="https://cdn.remember.school/comic_screen_39_we_hope_have_fun.png"/>,
    <div className="sources">
      <h1>Sources and Further Reading</h1>
      <p>
        First a <b>huge</b> thank you to <a href="https://ncase.me/">Nicky Case</a> for creating this comic and making
        it public domain.
        <br/><br/>
        For further reading on this subject check out: <br/>
        <ol>
          <li><a href="http://augmentingcognition.com/ltm.html">Augmenting Long-Term Memory</a>&nbsp;by Michael Nielsen
            which explains how Spaced Repetition is not just a tool for memorization, it's a tool to build deep
            understanding.
          </li>
          <li><a href="https://www.supermemo.com/en/archives1990-2015/articles/20rules">20 Rules for Cards</a>&nbsp;by
            Piotr Wozniak explains how to squeeze the most out of Spaced Repetition.
          </li>
        </ol>
        For sources on the science of learning and the benefits of techniques like spaced repetition, check out: <br/>
        <ol>
          <li><a href="https://www.amazon.com/Why-Dont-Students-Like-School/dp/047059196X/">Why Don't Students Like
            School?</a>
            &nbsp;by Daniel Willingham, which explains how "mere memorization" is actually necessary for creativity &
            critical thinking.
          </li>
          <li><a
            href="http://web.archive.org/web/20190711014620/http://tlcp.depaultla.org/wp-content/uploads/sites/2/2015/05/Dunlosky-et-al-2013-What-Works-What-Doesnt.pdf">What
            Works, What Doesn't [PDF]</a>
            &nbsp;by Dunlosky et al summarizes 200+ studies on various learning methods. In sum: common techniques like
            re-reading & highlighting don't work, less-common techniques like spacing & self-testing do.
          </li>
        </ol>
      </p>
    </div>
// TODO: This card is missing punctuation marks
// TODO: For 5, add context to the outfit. "There is actually a way to learn anything -- playing guitar, speaking french, mastering computer science --"
// TODO: For 23 and 24 replace this with a remember.school style card with question and answer
// TODO: Add a slide about how they can keep using this after the course as well to prepare for interviews and such
// TODO: Between 34 and 35, introduce a date & time picker for when to email them
// TODO: Make 35 have a more encouraging streak counter (higher number)
  ]

  return (
    <div>
      <div style={{
        position: "absolute", top: "0", left: "50%", transform: "translate(-50%, 120px)"
      }} className="comicWrapper">
        {imageIndex > 0 &&
        <Typography
          variant="body2"
          color="textSecondary"
          style={{textAlign: "left", cursor: 'pointer'}}
          onClick={decrementIndex}
          gutterBottom
        >
          ← Back
          <br/>
        </Typography>}
        <Card
          style={{textAlign: 'left', position: 'relative'}} elevation={10}
        >
          <CardContent style={{'backgroundColor': '#fff'}}>
            <Typography variant="body1">
              {imageIndex < imageHTML.length
                ? imageHTML[imageIndex]
                : <Redirect to=""/>
              }
            </Typography>
          </CardContent>
          <CardContent style={{backgroundColor: '#f5f5f5', height: '10%', cursor: 'pointer'}} onClick={incrementIndex}>
            <Typography variant="body2" color="textSecondary">
              Click here to continue
            </Typography>
          </CardContent>
          <FeedbackButton/>
        </Card>
        <div style={{paddingTop: 50}} />
      </div>
    </div>
  )
}
