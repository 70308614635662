import React, {Fragment, useState} from "react";
import useCallSecureApi from "./secureRequest";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export default function JoinCourse() {
  const [code, setCode] = useState()

  const callSecureApi = useCallSecureApi();
  const handleClick = () => {
    callSecureApi(`/join/${code}`, "POST")
    window.location.reload();
  }

  const handleChange = (event) => setCode(event.target.value)

  return (
    <Fragment>
      <TextField value={code} onChange={handleChange} placeholder="Enter your course code"/>
      <Button onClick={handleClick}>Join Course</Button>
    </Fragment>

  )
}
