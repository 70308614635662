import React, {Fragment, useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import LoopIcon from "@material-ui/icons/Loop";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FeedbackButton from "../feedback-button";
import MarkdownRenderer from "../MarkdownRenderer";
import prelude from "./prelude";
import useKeyboardShortcut from 'use-keyboard-shortcut';
import StudentFeedback from '../../components/StudentFeedback'

// Note: If you want to display react components using this method
//       then you will need to compile the react component into vanilla JS first.
//       As such, the best workflow is probably the following:
//          1) Write a react component
//          2) Compile to react
//          3) Run a script to escape the quotations and convert to the necessary lambda format
//          4) Store the lambda in a server and serve to users

// Example lambda:
// "() => {const x = Math.random(); return ['<div style=\"color: red;\">What is ' + x + ' *2</div>',x*2]};"

// TODO: Use an iframe to render this, and have another react app in the iframe communicating with listeners and events
export default function CardRenderer({hideRight, ...props}) {
  // Card Rendering state
  // TODO: change how the function which generates values is used to create a piece of html shown in the card
  const [side, setSide] = useState("front")
  const [q, setQ] = useState("")
  const [a, setA] = useState("")
  const [answerRequired, setAnswerRequired] = useState(false)
  const [userAnswer, setUserAnswer] = useState("")
  // Feedback student gives
  const [feedbackStatus, setFeedbackStatus] = useState(1)

  if (props.nextOnly) {
    hideRight = true
  }

  if (props.backOnly && side !== "back") {
    setSide("back")
  }

  // Functions to render
  useEffect(() => {
    // There may be optimization by not using eval
    const expr = prelude + "const f = " + props.card.script + "; f();";
    // console.log("------");
    // console.log(expr);
    // console.log("------");
    try {
      const evaluated = eval(expr)
      setQ(evaluated.question);
      setA(evaluated.answer);
      setAnswerRequired(evaluated.answerRequired === true)
      setSide('front')
    } catch (err) {
      return err
    }
  }, [props.card.script])

  useEffect(() => {
    if (side === 'back' && a === '') {
      buttonClickHandler(true)
    }
  }, [side])

  const buttonClickHandler = (remembered, isUnsure = false) => {
    if (side === 'back') {
      props.onButtonClick(userAnswer, remembered, isUnsure, feedbackStatus);
      clearUserAnswer()
      setFeedbackStatus(1)
      setSide('front');
    }
  };

  const handleFlipToBack = () => {
    const answer = getUserAnswer()

    const answerEmpty = () => {
      if (answer === '') return true
      if (isMultipleChoiceCard() || isCheckBoxesCard()) return answer.length === 0
      return false
    }

    if (answerRequired && answerEmpty()) {
      alert("You must provide an answer to continue")
      return
    }
    if (side === 'front') {
      setUserAnswer(answer)
      setSide('back')
    }
  }

  useKeyboardShortcut(["Meta", "Enter"], handleFlipToBack)
  // Why doesn't this work?
  useKeyboardShortcut(["1"], () => buttonClickHandler(true))
  useKeyboardShortcut(["2"], () => buttonClickHandler(false))
  useKeyboardShortcut(["3"], () => buttonClickHandler(true))


  return (
    <div style={{textAlign: "center"}}>
      <div
        style={{textAlign: 'left', position: 'relative'}} elevation={10} className="review-card"
      >
        {side === "back"
          ? (
            <Fragment>
              <div>
                <p variant="body1" gutterBottom style={{fontSize: props.smallText ? 10 : "default"}}>
                  <MarkdownRenderer>{q}</MarkdownRenderer>
                </p>
                {/*<br/>*/}
                {/*<br/>*/}
                {/*<Typography variant="body2" color="textSecondary" gutterBottom>*/}
                {/*    36% of top employers asked questions like this*/}
                {/*</Typography>*/}
              </div>
              <div style={{'backgroundColor': '#f5f5f5', padding: 20}}>
                <p className="card-content" style={{fontSize: props.smallText ? 10 : "default"}}>
                  <b>Answer:</b>
                  <MarkdownRenderer>{a}</MarkdownRenderer>
                </p>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <p className="card-content" style={{fontSize: props.smallText ? 10 : "default"}}>
                <MarkdownRenderer>{q}</MarkdownRenderer>
              </p>
              <button className="button" onClick={handleFlipToBack}>{a !== ''
                ? <span>Submit <span style={{fontWeight: "normal"}}>(Ctrl/⌘ + Enter)</span></span>
                : "Next (Ctrl/⌘ + Enter)"}</button>
            </Fragment>
          )
        }
        {!props.hideFeedback ? <FeedbackButton card={props.card} cardHistory={props.cardHistory}/> : undefined}
      </div>
      {side === 'back' && !hideRight ? (
        <div className="manual-grading">
          <br/>
          <b>Did you get this question right? Click to move on.</b>
          <br/><br/>
          <Button color="primary" variant="contained" startIcon={<DoneIcon/>} onClick={() => {
            buttonClickHandler(true);
          }}>Yes (1)</Button>
          <Button color="primary" variant="contained" startIcon={<LoopIcon/>} onClick={() => {
            buttonClickHandler(false);
          }}>No (2)</Button>
          <Button color="primary" variant="contained" className="last" startIcon={<ErrorOutlineIcon/>} onClick={() => {
            buttonClickHandler(true, true);
          }}>Unsure (3)</Button>
          <br/>
          <br/>
          <StudentFeedback feedbackStatus={feedbackStatus} setFeedbackStatus={setFeedbackStatus}/>
        </div>
      ) : undefined}
      {side === 'back' && props.nextOnly && <button className="button" onClick={props.onNext} style={{fontSize: "1.3em", float: "left"}}>
        <span>Next<span style={{fontWeight: "normal"}}></span></span>
      </button>}
    </div>
  )
}

function getUserAnswer() {
  if (isCheckBoxesCard()) {
    return getUserAnswerFromCheckBoxes()
  }
  if (isMultipleChoiceCard()) {
    return getUserAnswersFromMultipleChoiceCard()
  }
  const answerInput = document.getElementById("answer")
  return answerInput ? answerInput.value : ''
}

function isCheckBoxesCard() {
  return document.querySelectorAll('input[type="checkbox"]').length !== 0
}

function getUserAnswerFromCheckBoxes() {
  return Array.from(document.querySelectorAll('input[type="checkbox"]:checked')).map(elem => elem.value)
}

function isMultipleChoiceCard() {
  return document.querySelectorAll('input[type="radio"]').length !== 0
}

function getUserAnswersFromMultipleChoiceCard() {
  return Array.from(document.querySelectorAll('input[type="radio"]:checked')).map(elem => elem.value)
}

// TODO: Is there a cleaner way to incorporate the answer into this element's state?
function clearUserAnswer() {
  // console.log("clearing")
  const answerInput = document.getElementById("answer")
  if (answerInput) {
    answerInput.value = ''
  }
}

// TODO: might be useful to allow some card types a function which checks whether an answer is correct
