import React, {Fragment, useEffect, useState} from "react";
import useCallSecureApi from "./secureRequest";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";

const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

function ReminderSettingManager() {
  const [reminderSetting, setReminderSetting] = useState({})

  const callSecureApi = useCallSecureApi();
  useEffect(() => {
    callSecureApi("/email_reminder_setting").then(res => setReminderSetting(res));
  }, [])

  const updateSetting = () => callSecureApi("/email_reminder_setting", "PUT", reminderSetting)

  const setKey = (key, value) => setReminderSetting(oldSetting => {return {...oldSetting, [key]: value}})

  const handleChange = (event) => {
    setKey(event.target.name, event.target.checked)
  }

  const handleChangeInactiveStrategy = (event) => {
    if (event.target.checked) {
      setKey("inactiveStrategy", event.target.name)
    }
  }

  const handleChangeThreshold = (event) => {
    setKey(event.target.name, event.target.value)
  }

  return (isEmpty(reminderSetting) ? null :
    <div>
      <FormLabel component="legend">When I'm enrolled in a class, remind me to review on...</FormLabel>
      <FormGroup>
        {DAYS_OF_WEEK.map(day =>
          <FormControlLabel
            control={<Checkbox color="primary" checked={reminderSetting[day]} onChange={handleChange} name={day} />}
            label={capitalize(day)} key={day}
          />
        )}
      </FormGroup>
      <br/>
      <FormLabel component="legend">When I'm not enrolled in a class, remind me to review...</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Radio color="primary" checked={reminderSetting.inactiveStrategy === "active"} onChange={handleChangeInactiveStrategy} name="active" />}
          label="According to the same schedule as when I am enrolled in a class"
        />
        <FormControlLabel
          control={<Radio color="primary" checked={reminderSetting.inactiveStrategy === "threshold"} onChange={handleChangeInactiveStrategy} name="threshold" />}
          label={
            <Fragment>When I have <TextField
              size="small"
              type="number"
              name="cardThreshold"
              disabled={reminderSetting.inactiveStrategy !== "threshold"}
              value={reminderSetting.cardThreshold}
              onChange={handleChangeThreshold}
          /> or more cards to review</Fragment>}
        />
        <FormControlLabel
          control={<Radio color="primary" checked={!["active", "threshold"].includes(reminderSetting.inactiveStrategy)} onChange={handleChangeInactiveStrategy} name="none" />}
          label="Never"
        />
      </FormGroup>
      <br/><br/>
      <Button variant="contained" color="primary" onClick={updateSetting}>Save</Button>
    </div>
  )
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default ReminderSettingManager
