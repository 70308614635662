import React, { useState } from "react"
import { Button, Container, LinearProgress } from "@material-ui/core"
import EnhancedTextField from "../components/EnhancedTextField"
import useCallSecureApi from "../components/secureRequest"

export default function CreateCourse() {
  const [courseValues, setCourseValues] = useState({
    name: "",
    instructorName: "",
    description: "",
  })
  const [error, setError] = useState(false)
  const questions = [
    {
        target: "name",
        title: "Enter Course Name",
        blurb: `This will be the course title that the students will see on their
        dashboard. If you teach multiple courses with the same name, it may be
        helpful to add a unique identifier for the course to the name.`,
        label: "Course Name",
        placeholder: "Ex: Algorithms and Data Structures - Fall 2022",
        id: "course-name"
    },
    {
        target: "instructorName",
        title: "Enter Instructor Name",
        blurb: `We'll show this to your students so they know they're in the right class. 
        If there are multiple instructors, you can put all their names.`,
        label: "Instructor Name",
        placeholder: "Chris Callison-Burch",
        id: "instructor-name",
    },
    {
        target: "description",
        title: "Enter Course Description",
        blurb: `This lets both students and our model know what the course is about. 
        A link to your course page might also be helpful.`,
        label: "Course Description",
        id: "course-description",
        multiline: true
    },
  ]
  const [qIndex, setQIndex] = useState(0)
  const callSecureApi = useCallSecureApi()

  const createCourse = () => {
    setError(false)
    setQIndex(questions.length)
    callSecureApi("/course", "POST", {}, courseValues)
    .then((course) => {
        window.location = `/course/${course._id}/upload`
    })
    .catch(() => {
        setError(true)
        setQIndex(questions.length - 1)
    })
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: 150 }}>
      <h1>Create Course</h1>
      <LinearProgress variant="determinate" value={qIndex * 100.0 / questions.length} />
      <br />
      {error && (
        <h4 style={{ color: "red"}}>
          Our systems weren't able to process the request. 
          Please try again at a later time. Sorry for the inconvenience.
        </h4>
      )}
      {questions.map((element, index) => 
        index === qIndex &&
        <EnhancedTextField 
            key={element.target}
            {...element} 
            defaultValue={courseValues[element.target]}
            setValue={(value) => {
            const updated = courseValues
            updated[element.target] = value
            setCourseValues(updated)
        }}/>
      )}
      {qIndex > 0 && <Button
        size="large"
        variant="contained"
        color="primary"
        disableElevation
        style={{ fontSize: 15, borderRadius: 50, marginTop: 40, marginRight: 10 }}
        onClick={() => setQIndex(qIndex - 1)}>
        Prev
      </Button>}
      {qIndex < questions.length - 1 && <Button
        size="large"
        variant="contained"
        color="primary"
        disableElevation
        style={{ fontSize: 15, borderRadius: 50, marginTop: 40, marginRight: 10 }}
        onClick={() => courseValues[questions[qIndex].target] && setQIndex(qIndex + 1)}>
        Next
      </Button>}
      {qIndex === questions.length - 1 && <Button
        size="large"
        variant="contained"
        color="primary"
        disableElevation
        style={{ fontSize: 15, borderRadius: 50, marginTop: 40 }}
        onClick={() => courseValues[questions[qIndex].target] && createCourse(courseValues)}>
        Finish
      </Button>}
    </Container>
  )
}
