import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';

const LoginButton = (props) => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Button className="navbar-button" {...props}
            onClick={() => loginWithRedirect()}
        >
            Log In
        </Button>
    );
};

export default LoginButton;