import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from "./auth/protected-route";

import AllCourses from './views/AllCourses';
import CreateCourse from './views/CreateCourse';
import Course from './views/Course/'
import DailyReview from "./views/DailyReview";
import RankCards from "./views/RankCards";
import EditCard from "./views/EditCard";
import CreateCard from "./views/CreateCard";
import Comic from "./views/Comic";
import Settings from "./views/Settings";
import CourseAsStudent from "./views/CourseAsStudent"
import Dag from "./views/Dag";
import QuestionAnnotation from "./views/QuestionAnnotation"

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
import Navbar from "./components/navbar";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfService from "./views/TermsOfService";
import NlpDemo from "./views/NlpDemo";
import Ebbinghaus from "./views/comic-animations/Ebbinghaus";
import MyCards from "./views/MyCards";
import CourseSettings from "./views/UserSettings";
import SelectCards from "./views/SelectCards";
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MaterialUpload from "./views/MaterialUpload";
import IntroMessageToStudents from "./views/IntroMessageToStudents";
import Lti from "./views/Lti";
import CanvasPlatformRegistration from "./views/Lti/CanvasPlatformRegistration";
import AuthCheck from "./views/AuthCheck"
import LoginPopup from "./views/LoginPopup"

// Create your Own theme:
const theme = createTheme({
  palette: {
    secondary: {
      main: '#ff563f'
    },
    primary: {
      main: '#ff563f'
    }
  }
});

// const stripePromise = loadStripe('pk_test_51I0TjQFJbTtASmuqabIYdPDSCsBgPVOV2JXi1Q2dXTxa96pvpVjwEN1GLGfiQPuxjOQ57Y3PMkNli9Tlzfia8JzI008yWjXlrH');

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
      {/*<Elements stripe={stripePromise}>*/}
        <Router>
          <Auth0ProviderWithHistory>
            <Navbar/>
            {/* Should probably move this to App and make an index page for what is current in app */}
            <Route path="/" exact component={App}/>
            <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
            <Route path="/terms-of-service" exact component={TermsOfService}/>
            <ProtectedRoute path="/courses" exact component={AllCourses}/>
            <ProtectedRoute path="/courses/create_course" component={CreateCourse}/>
            <ProtectedRoute path="/course/:id/create_card" exact component={CreateCard}/>
            <ProtectedRoute path="/course/:id" exact component={Course}/>
            {/*Added user id for settings*/}
            <ProtectedRoute path="/course/:id/settings" exact component={CourseSettings}/>
            <ProtectedRoute exact path="/daily_review" component={DailyReview}/>
            <ProtectedRoute exact path="/card/:id/dag" component={Dag}/>
            <ProtectedRoute path="/edit_card/:id" exact component={EditCard}/>
            <Route path="/nlp_demo/:courseId" component={NlpDemo}/>
            <Route path="/tutorial" exact component={Comic}/>
            <ProtectedRoute path="/tutorial/:id" component={Comic}/>
            <ProtectedRoute path="/my_cards" exact component={MyCards}/>
            <ProtectedRoute path="/my_cards/create" exact component={CreateCard}/>
            {/* We could make the chapters public to show people how our product helps and do customer development? */}
            <Route path="/ebbinghaus" component={Ebbinghaus}/>
            <ProtectedRoute path="/settings" exact component={Settings}/>
            <ProtectedRoute path="/daily_review/:courseId" exact component={DailyReview}/>
            <ProtectedRoute path="/daily_review/:courseId/rankcards/:moduleNumber" exact component={RankCards}/>
            <ProtectedRoute path="/course-student/:courseId/quiz/:assignmentId" exact component={DailyReview}/>
            <ProtectedRoute path="/course-student/:courseId" exact component={CourseAsStudent}/>
            <ProtectedRoute path="/course-student/:courseId/quiz/:assignmentId/select_cards" exact
                            component={SelectCards}/>
            <ProtectedRoute path="/course/:courseId/source/:sourceId" exact component={QuestionAnnotation}/>
            <ProtectedRoute path="/course/:courseId/upload" exact component={MaterialUpload}/>
            <ProtectedRoute path="/intro_message" exact component={IntroMessageToStudents}/>
            {/*<ProtectedRoute path="/payment" component={Payment}/>*/}
            {/* LTI ROUTES */}
            <ProtectedRoute path="/lti" exact component={Lti}/>
            <ProtectedRoute path="/lti/registration" exact component={CanvasPlatformRegistration}/>
            <Route path="/authcheck" exact component={AuthCheck}/>
            <ProtectedRoute path="/authcheck/login" exact component={LoginPopup}/>
          </Auth0ProviderWithHistory>
        </Router>
      {/*</Elements>*/}
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
