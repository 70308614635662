import {Fragment} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const Todos = ({ students, course, cards, assignments }) => {
    const todos = [
        {
            name: "Add other instructors/TAs",
            description: "These users will also be invited to edit and manage the course with you. Click on the 'Course Settings' on the sidebar of this page, then fill out the textbox and hit invite. We'll mark this as complete once another instructor joins the course",
            isComplete: () => course.staff && course.staff.length > 0
        },
        {
            name: "Create Assignments",
            description: "Create an assignment by going to the 'Assignments' link in the sidebar, and then clicking the 'Create Assignment' button. You can add questions to this assignment and assign the assignment to students. Don't worry if you don't have questions or students yet -- we'll take care of those in the next todos! We'll mark this as complete once you create your first assignment.",
            isComplete: () => assignments.length > 0
        },
        {
            name: "Review Questions",
            description: "Once we've generated questions, you can review them by clicking on the 'review now' button that will appear to the right. We'll mark this as complete once you've accepted or rejected at least one question.",
            isComplete: () => cards.filter(card => card.tags.includes("ACCEPTED") || card.tags.includes("REJECTED")).length > 0
        },
        {
            name: "Share the course code with students",
            description: `The last step! Once your course is ready to share with students, copy the code in the sidebar and share it with your students via email, Piazza, or whatever system you use to communicate with students. If you want a sample message to send, just <a href='/intro_message?slug=${encodeURI(course.slug)}&instructorName=${encodeURI(course.instructorName)}' target='_blank'>click here</a>. We'll mark this step as complete once your first student joins the course.`,
            isComplete: () => students.length > 0
        }
    ]

    return (
        <>
            Steps to deploy questions to students:
            <div className="todos">
                <ul>
                {todos.map(todo =>
                    <Fragment key={todo.name}>
                    <div className={`${todo.isComplete() ? "done" : ""} todo`}>
                        <span style={{marginRight: 10}}>
                            {todo.isComplete() 
                            ? <FontAwesomeIcon style={{color: "green"}} icon={faCheck} />
                            : <FontAwesomeIcon style={{color: "red"}} icon={faXmark} />}
                        </span>
                        {todo.name}
                    </div>
                    {!todo.isComplete()
                        ? <span style={{opacity: 0.5}}><div
                        dangerouslySetInnerHTML={{__html: todo.description}}/></span>
                        : undefined
                    }
                    </Fragment>
                )}
                </ul>
            </div>
        </>
    )
    
}

export default Todos;
