import useUtils from "../utilities"

export default function IntroMessageToStudents(props) {
  const utilities = useUtils()
  const searchParams = utilities.getSearchParams(props.location.search)

  const slug = searchParams["slug"]
  const instructorName = searchParams["instructorName"]

  const paragraphs = [
    `Hi class!`,
    `From now on, we'll be using Martian for our quizzes in this course. You can sign up with your student email by going to learn.withmartian.com and entering our course code:`,
    `${slug}`,
    `Martian is a really cool platform because it makes use of some pretty advanced AI to help you study more effectively. It can generate an infinite number of practice problems, and it shows you the practice problems you need to study most. They have more information about that here: learn.withmartian.com/tutorial`,
    `We're trying something new to help you learn better, so please be very vocal in letting the folks who are building Martian know if there are any problems or anything which can be improved. (Yash and Etan, who are making the platform, can be reached at yash@withmartian.com / etan@withmartian.com)`,
    `We think it will be a big improvement, and we hope you have fun using it :)`,
    `Best,`,
    `${instructorName} & the course staff`
  ]

  return (
    <div style={{marginTop: 200, marginLeft: 50, maxWidth: 500}}>
      {paragraphs.map(p => <p>{p}</p>)}
    </div>
  )
}
