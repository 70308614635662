import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardRenderer from "../components/CardRenderer";
import useCallSecureApi from "../components/secureRequest";
import {useLocation} from "react-router-dom/cjs/react-router-dom";

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SelectCards(props) {
  const [histories, setHistories] = useState([])
  const [cards, setCards] = useState([])
  const [selected, setSelected] = useState({})
  const courseId = props.match.params.courseId
  const assignmentId = props.match.params.assignmentId

  const callSecureApi = useCallSecureApi()
  let query = useQuery();
  const useHistory = query.get('useHistory') != null

  const getHistoryFor = card => card ? histories.find(h => h.card === card._id) : null

  useEffect(() => {
    const dataEndpoint = `/assignment/${assignmentId}/all_cards`
    callSecureApi(dataEndpoint).then(responseData => {
      setHistories(responseData.histories)
      setCards(responseData.cards)
    });
  }, []);

  useEffect(() => {
    const result = {}
    for (const card of cards) {
      const cardHistory = getHistoryFor(card)
      const isActive = cardHistory != null ? cardHistory.scheduleType !== 'onetime' : false
      result[card._id] = !useHistory || isActive
    }
    setSelected(result)
  }, [cards])

  const handleChange = (card) => setSelected(oldSelected => {
    return {...oldSelected, [card._id]: !oldSelected[card._id]}
  })

  const updateSchedules = async () => {
    await callSecureApi(`/set_schedule`, "PUT", {}, {selected})
    window.location.href = `/course-student/${courseId}`
  }

  return (
    <>
      <div style={{backgroundColor: "#fff", minHeight: '100vh'}}>
        <Container maxWidth="md" style={{padding: 30, paddingTop: 100}}>
          <Typography variant="h2" gutterBottom>
            What questions do you want to keep reviewing in the future?
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            If you are not sure, leave all cards selected. Hit continue to save changes.
          </Typography>
          <Button variant="contained" onClick={updateSchedules}>Continue</Button>
          <div>
            {cards.map(card =>
              <CardSelector
                card={card}
                onChange={() => handleChange(card)}
                selected={selected[card._id] ? selected[card._id] : false}
                recommended={Math.random() < 0.25}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

function CardSelector(props) {
  return (
    <div className="card-selector-box">
      <Checker selected={props.selected} onChange={props.onChange}/>
      <div style={{display: 'inline-block', width: "90%", float: "right"}}>
        <CardRenderer
          card={props.card}
          onButtonClick={() => {
          }}
          hideRight
        />
        {props.recommended && <div className="recommended">Recommended</div>}
      </div>
    </div>
  )
}

function Checker(props) {
  return (
    <button onClick={props.onChange} className={props.selected ? "checkbox checked" : "checkbox unchecked"}/>
  )
}