import React, {useEffect, useState} from "react";
import useCallSecureApi from "../components/secureRequest";
import CardRenderer from "../components/CardRenderer";


export default function EditCard(props) {
  const [card, setCard] = useState({
    script: '// This is a placeholder card because the card you requested could not be loaded\n' +
      '() => {\n' +
      '  return {question: \'q\', answer: \'a\'}\n' +
      '}',
    tags: ['some', 'fake', 'tags', 'here']
  })

  const callSecureApi = useCallSecureApi();
  useEffect(() => {
    if (props.match.params.id) {
      callSecureApi(`/card/${props.match.params.id}`).then(responseData => {
        setCard(responseData);
      });
    }
  }, [props.match.params.id]);

  return (
    <section id="dag-content">
      <div className="container" style={{"width": "95%"}}>
        <div className="col-25 left">
          <h4>To Answer This Question, Students Should Know...</h4>
          <h6>How to compute derivatives</h6>
          <h6>Definition of Linearity</h6>
          <h6>Examples of Linear Equations</h6>
        </div>
        <div className="col-50">
          <CardRenderer
            card={card}
            onButtonClick={() => {
            }}
          />
          <div className="button"
               style={{"width": "fit-content", "marginBottom": "3em", marginTop: 50, fontSize: "1.3em"}}>Edit This
            Question
          </div>
        </div>
        <div className="col-25 right">
          <h4 style={{"textAlign": "center"}}>Students Can Use This Question For...</h4>
          <h6 className="added-concept">Nonlinearity</h6>
          <h6 className="added-concept">Volta-Loktera</h6>
          <h6 className="added-concept">Adding Macrophages To The Bacteria Equation</h6>
          <div className="button" style={{"marginLeft": "auto", "marginRight": "auto"}}>Add Dependent</div>
        </div>
        <div style={{"clear": "both"}}/>
      </div>
    </section>
  )
}
