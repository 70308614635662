export function cardNeedsReview(card, sourceId) {
  return cardHasSource(card, sourceId) && !isCardAccepted(card) && !isCardRejected(card)
}

export function cardHasSource(card, sourceId) {
  return card.tags.includes(`SOURCE: ${sourceId}`)
}

export function getCardSourceId(card) {
  const START = "SOURCE: "
  const sourceTag = card.tags.find((s) => s.startsWith(START))
  if (typeof sourceTag != "string" || sourceTag.length <= START.length) {
    return null
  }
  return sourceTag.substring(START.length)
}

export function isCardAccepted(card) {
  return card.tags.includes(`ACCEPTED`)
}

export function isCardRejected(card) {
  return card.tags.includes(`REJECTED`)
}