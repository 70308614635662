import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {CardActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useCallSecureApi from "../components/secureRequest";
import Grid from "@material-ui/core/Grid";
import CourseLogo from "../images/robot.png"
import OverdueAssignmentThumbnail from "../images/overdue-assignment-thumbnail.png"


export default function CourseAsStudent(props) {
  const courseId = props.match.params.courseId
  console.log("COURSE ID FRONTEND: ", courseId)
  const [course, setCourse] = useState({});
  const [assignments, setAssignments] = useState([])
  const [cardsToStudy, setCardsToStudy] = useState(0)

  const callSecureApi = useCallSecureApi();

  useEffect(() => {
    callSecureApi(`/course-student/${courseId}/`).then(res => {
      setCourse(res)
      setAssignments(res.assignments)
    })
    callSecureApi(`/course/${courseId}/all_cards`).then(res => setCardsToStudy(res.cards.length))
  }, [])

  const totalCards = assignments.reduce((b, assignment) => b + assignment.cards.length, 0)

  return (
    <>
      <div className="container">
        <section style={{position: "relative", zIndex: 50}} id="course-overview">
          <div id="course-writeup">
            <h1>{course.name}</h1>
            <h2>Taught by Chris Callison-Burch</h2>
            <p>
              {course.description}
            </p>
          </div>
          {courseId !== "63983d9b44d93e5189ac2160" &&
            <div id="course-status">
              <img
                src={CourseLogo}
                alt="logo"
                style={{
                  display: "block",
                  marginTop: 15,
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              />
              <div className="course-fact">
                <h3>{assignments.length}</h3>
                <p>Interactive quizzes</p>
              </div>
              <div className="course-fact">
                <h3>{totalCards}</h3>
                <p>concepts and cards</p>
              </div>
              <div style={{clear: "both"}}/>
              <a href={`/daily_review/${courseId}`} className="button">Study This Course</a>
              <div style={{clear: "both"}}/>
            </div>
          }
          <div style={{clear: "both"}}/>
        </section>
        {courseId === "63983d9b44d93e5189ac2160"
          ? [...Array(15).keys()].slice(1,15).map(moduleNumber =>
            <ModuleAnnotationCard moduleNumber={moduleNumber} courseId={courseId}/>
          )
          : <section id="assignments">
            <AssignmentAccordion
              title="Overdue Assignments"
              assignments={assignments.filter(isOverdue)}
              courseId={props.match.params.courseId}
            />
            <AssignmentAccordion
              title="Upcoming Assignments"
              assignments={assignments.filter(assignment => !(isPast(assignment) || isOverdue(assignment)))}
              courseId={props.match.params.courseId}
            />
            <AssignmentAccordion
              title="Past Assignments"
              assignments={assignments.filter(isPast)}
              courseId={props.match.params.courseId}
            />
          </section>
        }
      </div>
    </>
  )
}

function sortAssignmentsMethod(a, b) {
  // first sort by due date
  if (a.dueDate && b.dueDate) {
    if (a.dueDate === b.dueDate) {
      // sort by name
      return a.name < b.name ? -1 : 1
    }
    return a.dueDate < b.dueDate ? -1 : 1
  } else if (a.dueDate) {
    return -1
  } else if (b.dueDate) {
    return 1
  } else {
    return 0
  }
}

function AssignmentAccordion({title, assignments, courseId}) {
  if (assignments.length === 0) return null
  return (
    <Accordion elevation={0} defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        <h2 className="course-title">{title} ({assignments.length})</h2>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {assignments.sort(sortAssignmentsMethod).map(assignment => <Grid item xs={12}><AssignmentCard assignment={assignment}
                                                                            courseId={courseId}/></Grid>)}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

function AssignmentCard({assignment, courseId}) {
  return (
    <Card variant="outlined" style={{border: 0}}>
      <CardContent>
        <img
          src={OverdueAssignmentThumbnail}
          className="thumbnail-img"
        />
        <div className="thumbnail-txt">
          <h4>
            {assignment.name}
          </h4>
          <p>
            Due {assignment.dueDate ? dateToMMDDYYYY(assignment.dueDate) : "TBD"}
            | {assignment.cards.length} questions
            | Estimated Completion
            Time: {Math.ceil(assignment.cards.length * 0.5 + 5)} minutes
          </p>
          <br/>
          <LinearProgress variant="determinate"
                          value={assignment.cardHistories.length / assignment.cards.length * 100}/>
          <br/>
          <CardActions>
            <div style={{maxWidth: "100%"}}>
              <a className="button">Start Reading</a>
              <a className="button" size="small" color="primary"
                 href={`/course-student/${courseId}/quiz/${assignment._id}`}>
                {assignment.cardHistories.length === 0 ? "Start Quiz" : "Continue Quiz"}
              </a>
              {assignment.complete
                ? <a
                  className="button"
                  style={{width: "10em"}}
                  size="small"
                  color="primary"
                  href={`/course-student/${courseId}/quiz/${assignment._id}/select_cards?useHistory=true`}
                >
                  See All Questions
                </a>
                : undefined
              }
            </div>

            {/*<Button size="small" color="primary">*/}
            {/*  Share*/}
            {/*</Button>*/}
          </CardActions>
        </div>
      </CardContent>
    </Card>
  )
}

function dateToMMDDYYYY(d) {
  return swap(d.toString().slice(0, 10).split('-').reverse(), 0, 1).join('/')
}

function swap(arr, i, j) {
  [arr[i], arr[j]] = [arr[j], arr[i]];
  return arr
}

function isOverdue(assignment) {
  return easternDate(new Date(assignment.dueDate)) < new Date() && !isPast(assignment)
}

function isPast(assignment) {
  return assignment.complete
}

function addHours(numOfHours, date = new Date()) {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
}

// Given a date object which has time in GMT-4 but is interpreted as GMT
// Return a new date object which is interpreted as GMT-4
// Example:
//   `new Date("2022-06-25T23:59:59.000Z")` normally gives us
//   `Sat Jun 25 2022 19:59:59 GMT-0400 (Eastern Daylight Time)`
//   If we wanted `Sat Jun 25 2022 23:59:59 GMT-0400 (Eastern Daylight Time)`
//   Then we could run `easternDate(new Date("2022-06-25T23:59:59.000Z"))`
// TODO: Make sure we use dates consistently and with timezones across the frontend and backend
function easternDate(date) {
  const newDate = new Date(date)
  return addHours(4, newDate)
}

function ModuleAnnotationCard({moduleNumber, courseId}) {
  return (
    <Card variant="outlined" style={{border: 0}}>
      <CardContent>
        <img
          src={OverdueAssignmentThumbnail}
          className="thumbnail-img"
        />
        <div className="thumbnail-txt">
          <h4>
            Module {moduleNumber}
          </h4>
          <br/>
          <br/>
          <CardActions>
            <div style={{maxWidth: "100%"}}>
              <a className="button" size="small" color="primary"
                 href={`/daily_review/${courseId}/rankcards/${moduleNumber}`}
              >
                Review This Module
              </a>
            </div>
          </CardActions>
        </div>
      </CardContent>
    </Card>
  )
}