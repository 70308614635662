import './App.css';
import React, {useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import AuthenticationButton from "./components/authentication-button";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Hidden} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LoopIcon from '@material-ui/icons/Loop';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from "react-router-dom";

import mike from './images/mike.jpg'
import dijkstra from './images/dikjstra.png';
import Paper from "@material-ui/core/Paper";
import ExampleMaterial from './images/lecture-notes-example-kosarajus.png';
import ExampleLogin from './images/student-question-review-example.png';

const HOW_TO = [
  {
    title: '1. Send us your course material and roster',
    description: 'That\'s all the setup for the platform. We even integrate with your course on Piazza to ' +
      'answer student questions and ease TA workload. We make using Martian no extra work for you.'
  },
  {
    title: '2. We create high quality questions',
    description: 'We break the material you send us into core concepts. For each concept in the course, we ' +
      'create questions to help students learn, gauge student understanding, and improve student studying. ' +
      'These questions range from traditional flashcards to high-level problem generators with autograders.'
  },
  {
    title: '3. Students log in and review those questions',
    description: 'This is where the magic happens! We show students material on an optimal study schedule ' +
      'using a clinically backed technique called spaced repetition. This lets student learn more effectively, ' +
      'using less time, and remembering the concepts longer.'
  }
]

function App() {
  const {isLoading, isAuthenticated} = useAuth0()
  const [activeIndex, setActiveIndex] = useState(0)

  if (isAuthenticated) {
    return <Redirect to="courses"/>
  }

  if (isLoading) {
    return <CircularProgress style={{position: "absolute", top: "50%", left: "50%"}}/>;
  }

  return (
    <div className="App" style={{'overflow-x': 'hidden', 'width': '100vw'}}>
      <Grid container spacing={3} style={{'padding': '10%'}}>
        {/* Landing */}
        <Grid item sm={6} xs={12} style={{'textAlign': 'left'}}>
          {/* Desktop Above-the-fold text */}
          <Hidden xsDown>
            <Typography variant="h2" gutterBottom style={{'fontWeight': 'normal'}}>
              Maximally effective tools for learning
            </Typography>
            <Typography variant="h5" gutterBottom style={{'fontWeight': 'normal'}}>
              Educational psychology has discovered enormously effective techniques for improving learning.
              We let you easily deploy these techniques in the classroom through a Duolingo-style app.
            </Typography>
          </Hidden>

          {/* Mobile Above-the-fold text */}
          <Hidden smUp>
            <Typography variant="h4" gutterBottom style={{'fontWeight': 'bold'}}>
              Maximally effective tools for learning
            </Typography>
            <Typography variant="h6" gutterBottom style={{'fontWeight': 'normal'}}>
              Educational psychology has discovered enormously effective techniques for improving learning.
              We let you deploy these techniques in the classroom through a Duolingo-style app.
            </Typography>
          </Hidden>
          <Button size="large" variant="contained" color="primary" disableElevation
                  style={{'font-size': 20, 'border-radius': 50, 'margin-top': 10,}}
                  href="https://form.typeform.com/to/LoRePoZu"
          >
            Request a demo
          </Button>
          <AuthenticationButton size="large" variant="contained" disableElevation className="homepage-inline-login">

          </AuthenticationButton>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={6} style={{'text-align': 'center', 'backgroundColor': '#ff563f'}}>
            <Card style={{'height': 400, width: 320, 'margin': 'auto', 'textAlign': 'left'}} elevation={10}>
              <CardContent style={{'backgroundColor': '#fff', 'height': '30%'}}>
                <Typography variant="body1" gutterBottom>
                  Why is the algorithm for finding the shortest path in a DAG faster than Dijkstra's?
                </Typography>
              </CardContent>
              <CardContent style={{'backgroundColor': '#fff', 'height': '50%'}}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Hint:
                  <img
                    alt="Dijkstra's Algorithm"
                    src={dijkstra}
                    style={{'max-width': '100%', 'max-height': '100%'}}
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Hidden>

        <Grid item xs={12}>
          <br/>
          <br/>
          <br/>
        </Grid>

        {/* Testimonials */}
        <Grid item sm={6} xs={12} style={{"text-align": "left"}}>
          <Grid container>
            <Grid item sm={1} xs={2}>
              <Avatar alt="Kevin C."
                      src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Asian_man.jpg"/>
            </Grid>
            <Grid item sm={11} xs={10}>
              <div>
                Signing up was a no-brainer. Before using Martian
                I got an interview at Amazon, but forgot what I
                learned in class and failed the interview. If I
                had something like Martian, I would have made
                almost $30K more.
              </div>
              <Typography variant="button" color="primary"> Kevin C. (SEAS Student) </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12} style={{"text-align": "left"}}>
          <Grid container>
            <Grid item sm={1} xs={2}>
              <Avatar alt="Mike A."
                      src={mike}/>
            </Grid>
            <Grid item sm={11} xs={10}>
              <div>
                Having worked as both a CIS121 TA and an engineer at
                Facebook, I know how important remembering
                this material is. Martian is a life saver.
              </div>
              <Typography variant="button" color="primary"> Mike A. (SEAS Student) </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3}></Grid>
        <Grid item sm={6} xs={12} style={{"text-align": "left"}}>
          <Grid container>
            <Grid item sm={1} xs={2}>
              <Avatar alt="Kundan U."
                      src="https://c1.wallpaperflare.com/preview/990/336/955/rishi-gangoly-man-indian-professional-male-thinking.jpg"/>
            </Grid>
            <Grid item sm={11} xs={10}>
              <div>
                It’s surprising how many people go into technical interviews unprepared.
                This stuff is fundamental — being a good engineer and getting hired
                depend on it. Martian is the best way I've seen to stay prepared.
              </div>
              <Typography variant="button" color="primary">
                Kundan U.
                (CEO of a tech-focused staffing firm)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item xs={12}>
          <br/>
          <br/>
          <br/>
        </Grid>

        {/* How It Works */}
        <Grid item sm={6} xs={12} style={{'textAlign': 'left'}}>
          <Typography variant="h3">
            How It Works
          </Typography>
          <div style={{'margin': 20}}>
            {HOW_TO.map((section, i) => (
              <Paper onClick={() => setActiveIndex(i)}
                     className={activeIndex === i ? "hover-paper active" : "hover-paper"} elevation={0}
                     style={{cursor: "pointer"}}>
                <Typography variant="h6" color="primary">
                  {section.title}
                </Typography>
                <Typography variant="subtitle1">
                  {section.description}
                </Typography>
              </Paper>
            ))}
          </div>
        </Grid>
        <Grid item sm={6} xs={12} style={{'textAlign': 'center', 'backgroundColor': '#ff563f'}}>
          <div className="example-material" style={{
            'position': 'relative',
            'top': '50%',
            'transform': 'translateY(-50%)',
            'z-index': '1',
            display: activeIndex === 0 ? "block" : "none"
          }}>
            <img src={ExampleMaterial} alt="example material" width="100%"/>
          </div>
          <div className="example-question" style={{
            'position': 'relative',
            'top': '50%',
            'transform': 'translateY(-50%)',
            'z-index': '1',
            display: activeIndex === 1 ? "block" : "none"
          }}>
            <Card style={{
              'height': 400,
              'width': 320,
              'margin': 'auto',
              'text-align': 'left'
            }} elevation={10}>
              <CardContent style={{'backgroundColor': '#fff', 'height': '30%'}}>
                <Typography variant="body1" gutterBottom>
                  What is the running time of Kosaraju's algorithm?
                </Typography>
                <br/>
                <br/>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  For reference, see section 19.2 of the lecture notes
                </Typography>
              </CardContent>
              <CardContent style={{'backgroundColor': '#f5f5f5', 'height': '60%'}}>
                <Typography variant="body1" gutterBottom>
                  <b>Answer:</b> O(V+E) <br/>
                  <b>Explanation:</b> Let's look at the steps of Kosaraju's algorithm. <br/>
                  1. Run a DFS on G to label each vertex with its finishing time <b>O(V + E)</b> <br/>
                  2. Compute the transpose of G <b>O(E)</b> <br/>
                  3. Run a DFS on G tranpose in decreasing order of finishing time <br/><b>O(V + E)</b>
                </Typography>
              </CardContent>
            </Card>
            <Button startIcon={<LoopIcon/>}>Didn't Remember</Button>
            <Button startIcon={<DoneIcon/>}>Remembered</Button>
          </div>
          <div className="example-login" style={{
            'position': 'relative',
            'top': '50%',
            'transform': 'translateY(-50%)',
            'z-index': '1',
            display: activeIndex === 2 ? "block" : "none"
          }}>
            <img src={ExampleLogin} width="100%" alt="exmaple login"/>
          </div>
        </Grid>
      </Grid>

      {/* Footer */}
      <Container style={{'backgroundColor': '#ff563f', 'color': '#fff', 'padding': '5%', 'textAlign': 'left'}}>
        <Typography variant="h4" gutterBottom>
          Instructors And Students Love Martian
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Our goal is to make the most effective tools for learning. In order to do this, we survey students
          about their experience with the platform. &gt;90% of students prefer Martian to traditional
          techniques used in college classrooms, such as reading quizzes. Trust those students -- you'll love it!
        </Typography>
        <Button size="large" variant="outlined" color="primary" disableElevation
                style={{'font-size': 20, 'border-radius': 50, 'margin-top': 20, 'backgroundColor': '#fff'}}
                href="https://form.typeform.com/to/LoRePoZu"
        >
          Request A Demo
        </Button>
      </Container>
    </div>
  );
}

export default App;
