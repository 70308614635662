// This file is appended to all card lambdas before running them.
// This allows us to provide a standard module of functions.
// In cards, those functions are referenced via prelude.function
// Read preludeNotes before editing this file

const prelude = `
/* Multiple Choice Cards */

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array
}

// For markdown to work, the entire card needs to be placed on one line
// TODO: Make the cards more readable (potentially by allowing them to spread across many lines)
// NOTE: This entirely breaks if the user puts a newline in one of their multiple choice options
const optionForm = (choiceGenerator, options, randomize=true) => String.raw\`<form>\${(randomize ? shuffleArray(options) : options).map(option => choiceGenerator(option)).join("  <br\\>")}</form>\`

const multipleChoice = (prompt, options, answer, randomize=true) => {  
  const choice = (t) => \`<input autocomplete="off" type="radio" name="question" value="\${t}"/><label for="css">\${t}</label>\`
  
  return {
    question: String.raw\`\${prompt} <br/>\${optionForm(
      choice,
      options,
      randomize
    )}\`, 
    answer: String.raw\`\${answer}\`
  }
}

const checkBoxes = (prompt, options, answer, randomize=true) => {  
  const choice = (t) => String.raw\`<input autocomplete="off" type="checkbox" value="\${t}"><label>\${t}</label>
  \`
  
  return {
    question: String.raw\`\${prompt} <br/>\${optionForm(
      choice,
      options,
      randomize
    )}\`, 
    answer: String.raw\`\${answer}\`
  }
}


/* Simple Cards */

const shortAnswer = (text, answer) => {
  return {
    question: String.raw\`\${text} <br/> <input autocomplete="off" id="answer"/>\`,
    answer: answer
  }
}

const longAnswer = (text, answer) => {
  return {
    question: String.raw\`\${text} <br/> <textarea id="answer" autocomplete="off" rows="5" cols="33"/>\`, 
    answer: answer
  }
}


/* Cloze Cards */

const CLOZURE = /{{(?<id>.*?)::(?<text>[\\s\\S]*?)}}/g

// Take a string with some anki-style cloze deletions and generate
// Return a list of questions and answers

function clozeDeletion(s, i=0) {
    return clozeStringToCardObjects(s)[i]
}

function clozeStringToCardObjects(s) {
  return getAllMatches(s, CLOZURE).map(toCardObject)
}

function getAllMatches(s, regex) {
  return [...s.matchAll(regex)]
}

function toCardObject(regexMatch) {
  return {
    answer: getAnswer(regexMatch),
    question: getQuestion(regexMatch)
  }
}

function getAnswer(regexMatch) {
  return regexMatch.groups.text
}

function getQuestion(regexMatch) {
  return replaceBetween(
    regexMatch.input,
    regexMatch.index,
    regexMatch.index + regexMatch[0].length,
    String.raw\`<input autocomplete="off" id="answer"/>\`
  ).replace(CLOZURE, replaceClozeWithText)
}

// The string.replace function expects the following function signature
// unused parameters are marked with an underscore
function replaceClozeWithText(_match, _p1, p2, _p3, _offset, _string) {
  return p2
}

function replaceBetween(origin, startIndex, endIndex, insertion) {
  return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
}

function toScript(object) {
  return \`() => { return \${JSON.stringify(object)}}\`
}


/* User Generated Code */
`
export default prelude
