import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function AuthCheck() {
    const { isAuthenticated, isLoading } = useAuth0();
    const history = useHistory()
    const query = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (isAuthenticated) redirect()
    }, [isLoading])

    const redirect = () => {
        const redirectUrl = query.get('redirect') ?? '/'
        query.delete('redirect')
        history.push(redirectUrl + "?" + query.toString())
    }

    const handleLogin = () => {
        const popup = window.open('/authcheck/login', 'popup', 'width=600,height=600')
        setInterval(() => {
            if (popup.closed) {
                window.location.reload(false)
            }
        }, 1000)
    }

    if (isLoading) {
        return (
            <div style={{textAlign: "center", marginTop: 50}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{textAlign: "center"}}>
            <h1>Sign In to Martian</h1>
            <Button size="large" variant="contained" color="primary" disableElevation
                    style={{fontSize: 20, borderRadius: 50}}
                    onClick={handleLogin}
            >
            Login
            </Button>
        </div>
    )
}