import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import gfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeHighlight from 'rehype-highlight'
import "../katex/katex.min.css" // `rehype-katex` does not import the CSS for you
import "../highlightjs/atom-one-dark.min.css"

// TODO: Because it's a string, we might need to escape certain characters, e.g.
//  need \\frac{1}{2} for it to work
//  need \(\) for parens to work
const Markdown = (props) => <ReactMarkdown
  remarkPlugins={[gfm, remarkMath]}
  rehypePlugins={[rehypeRaw, rehypeKatex, rehypeHighlight]}
  {...props}
/>;

export default Markdown;
