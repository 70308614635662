import Button from "@material-ui/core/Button";
import AuthenticationButton from "./authentication-button";
import React, {Fragment, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import navbarLogo
  from "../images/navbar-logo-martian.png";

export default function Navbar() {
  const {isAuthenticated} = useAuth0();
  const [drawerOpen, toggleDrawerOpen] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('hideNavBar') === 'true') {
    return <Fragment/>;
  }
  
  return (
    <Fragment>
        <div id="navbar-desktop">
          <div className="navbar-container">
            <div className="navbar-image">
              <Link to="/" style={{textDecoration: 'none', color: "#000"}}>
                <img src={navbarLogo} alt="logo" style={{'height': 100}}/>
              </Link>
            </div>
            <div className="navbar-buttons">
              {isAuthenticated ? <Button className="navbar-button" href="/courses">Dashboard</Button> : undefined}
              {isAuthenticated ? <Button className="navbar-button" href="/settings">Settings</Button> : undefined}
              <AuthenticationButton/>
            </div>
            <div style={{"clear": "both"}}/>
          </div>
        </div>
      <div id="navbar-mobile">
        <div style={{'position': 'absolute', 'top': 16, 'right': 16}}>
          <IconButton onClick={() => toggleDrawerOpen(true)}>
            <MenuIcon/>
          </IconButton>
        </div>
        <Drawer anchor="top" open={drawerOpen} onClose={() => toggleDrawerOpen(false)}>
          <div style={{margin: 16}} onClick={() => toggleDrawerOpen(false)}>
            <Link to="/" style={{textDecoration: 'none', color: "#000"}}>
              <img src={navbarLogo} alt="logo" style={{'height': 100}}/>
            </Link>
            <br/>
            <Link to="/courses" style={{textDecoration: 'none', color: "#000", margin: 16}}>
              Dashboard
            </Link>
            <br/>
            <br/>
            <Link to="/settings" style={{textDecoration: 'none', color: "#000", margin: 16}}>
              Settings
            </Link>
            <br/>
            <br/>
            <Link to="/daily_review" style={{textDecoration: 'none', color: "#000", margin: 16}}>
              Daily Review
            </Link>
          </div>
        </Drawer>
      </div>
    </Fragment>
  );
}
