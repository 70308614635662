import {Fragment, useState, useEffect} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faLayerGroup, faPencil} from '@fortawesome/free-solid-svg-icons'
import Todos from '../Todos'
import { getCardSourceId, isCardAccepted, isCardRejected } from '../../../utilities/cardFilters'

const HomeTab = ({ course, students, cards, assignments, sources, updateCourseField, updateCourse}) => {
    const [editHeader, setEditHeader] = useState(false)
    const readySources = cards.reduce((acc, card) => {
        const sourceId = getCardSourceId(card)
        if (sourceId != null && !isCardAccepted(card) && !isCardRejected(card)) {
          acc.add(sourceId)
        }
        return acc
      }, new Set()); 
    return (
        <Fragment>
            <div id="course-writeup" style={{width: "90%"}}>
              {editHeader
                ? <Fragment>
                  <input
                    type="text" id="edit-course-name" name="edit-course-name" value={course.name}
                    onChange={e => updateCourseField("name")(e.target.value)}
                    style={{marginTop: 40}}
                  />
                  <FontAwesomeIcon
                    icon={faCheck}
                    onClick={() => {
                      setEditHeader(x => !x)
                      updateCourse()
                    }}
                    style={{transform: "translate(0px,-5px) scale(2, 2)", marginLeft: 15}}
                  />
                  <h2>
                    Taught by
                    <input
                      type="text" id="edit-instructor-name" name="edit-instructor-name" value={course.instructorName}
                      onChange={e => updateCourseField("instructorName")(e.target.value)}
                      style={{display: 'inline'}}
                    />
                  </h2>
                  <textarea
                    style={{width: "100%", minHeight: 100}} value={course.description}
                    onChange={e => updateCourseField("description")(e.target.value)}
                  />
                </Fragment>
                : <Fragment>
                  <h1>
                    {course.name}
                    <FontAwesomeIcon
                      icon={faPencil}
                      onClick={() => setEditHeader(x => !x)}
                      style={{transform: "translate(0px,-10px) scale(0.7, 0.7)"}}
                    />
                  </h1>
                  <h2>Taught by {course.instructorName}</h2>
                  <p>
                    {course.description}
                  </p>
                </Fragment>}
              <div className="col-50 internal-padding"
                   style={{backgroundColor: "#eeeeee", marginRight: "2%", width: "48%", padding: 20}}>
                <Todos students={students} course={course} cards={cards} assignments={assignments}/>
              </div>
              <div className="col-50 internal-padding" style={{backgroundColor: "#eeeeee", padding: 20}}>
                <div
                  className="button" style={{width: "250px", marginTop: 0, marginLeft: 'auto', marginRight: 'auto'}}
                  onClick={() => window.location = `/course/${course._id}/upload`}
                >
                  <FontAwesomeIcon icon={faLayerGroup}/> Generate new questions
                </div>

                {sources.length > 0 && <div style={{marginBottom: 10}}>Questions generated for you:</div>}
                {sources.map(source =>
                  <div key={source._id} className="compound-button" style={{marginBottom: 10, display: 'flex', alignItems: 'center'}}>
                    <div style={{width: "70%", float: "left", overflow: "hidden", marginRight: "10px"}}>{source.name}</div>
                    {readySources.has(source._id) ? 
                    <div
                      className="internal-button clickable"
                      onClick={() => window.location = `/course/${course._id}/source/${source._id}`}
                    >
                      Review now
                    </div>
                    :
                      <div style={{
                        textAlign: "center", width: "27%", float: "right", padding: 5, border: "2px solid"
                      }}>
                        Generating...
                      </div>
                    }
                    <div style={{clear: 'both'}}/>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
    )
}

export default HomeTab