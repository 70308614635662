import Chip from "@material-ui/core/Chip";
import {Button, MenuItem, Select} from "@material-ui/core";
import React, {useState} from "react";
import {DataGrid} from "@material-ui/data-grid";

const DEFAULT_USER = {email: "name@email.com", tags: ["tag1", "tag2"], isStudent: true, id: 0}

// The api expect only email and tags, so we remove the unwanted fields
const removeFieldsFrom = (aUser) => {
  const {id, user, isStudent,...result} = aUser
  return result
}

export default function RosterGrid(props) {
  const [newUser, setNewUser] = useState({...DEFAULT_USER})
  const rosterColumns = [
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 200,
      renderCell: (params) => {
        // This try catch is for the small interval during which the DataGrid renders its own data instead of the rows
        try {
          return params.value.sort().map(tag => <Chip style={{margin: 5}} label={tag}/>)
        } catch {
          return params.value
        }

      },
      editable: true
    },
    {
      field: "isStudent",
      headerName: "Type",
      width: 200,
      renderCell: (params) => {
        if (params.id === 0) return (
          <Select
            value={newUser.isStudent}
            onChange={(e) => setNewUser((user) => {return {...user, isStudent: e.target.value}})}
            disableUnderline
          >
            <MenuItem value={true}>Student</MenuItem>
            <MenuItem value={false}>Staff</MenuItem>
          </Select>
        )
        return <span style={{fontSize: "1rem"}}>{params.value ? "Student" : "Staff"}</span>
      },
      editable: false
    },
    {
      field: "user",
      headerName: "Has Signed Up",
      width: 200,
      renderCell: (params) => {
        if (params.id === 0) return (
          <AddUserButton
            addUser={() => {
              if (newUser.email !== DEFAULT_USER.email) {
                props.addUser(removeFieldsFrom(newUser), newUser.isStudent)
                setNewUser({...DEFAULT_USER})
              }
            }}
            text={`Add ${newUser.isStudent ? "Student" : "Staff"}`}
          />
        )
        return params.value ? "✅" : "❌"
      },
      editable: false
    }
  ]

  const handleCellEditCommit = ({ id, field, value }) => {
    if (id === 0) {
      setNewUser(newUser => {
        newUser[field] = field === "tags" ? tagsFromValue(value) : value
        return newUser
      })
    } else if (field === 'tags') {
      // The way in which we're setting students isn't very efficient, but it's simple
      const targetUser = props.users.find(user => user._id === id)
      const packagedUser = {...removeFieldsFrom(targetUser), tags: tagsFromValue(value)}
      props.onTagsEditCommit(packagedUser, targetUser.isStudent)
    }
  }

  const tagsFromValue = (value) => {
    if (typeof (value) === 'string') {
      return value.split(',').map(tag => tag.trim())
    }
    return value
  }

  return <DataGrid
    selectionModel={props.selectionModel}
    onSelectionModelChange={props.onSelectionModelChange}
    rows={[
        newUser,
      ...props.users.map(user => {return {...user, 'id': user._id}})
    ]}
    columns={rosterColumns}
    isCellEditable={params => params.id === 0 || params.field === 'tags'}
    isRowSelectable={params => params.id !== 0}
    disableSelectionOnClick
    checkboxSelection
    onCellEditCommit={handleCellEditCommit}
  />
}

// TODO: Add toast on submit
//  If the response is successful, say that in the toast, add the user to the list of all users, and clear the newUser
//  If the response failed, say that in the toast
function AddUserButton({ addUser, text }) {
  return (
    <Button
      onClick={addUser}
      variant="contained"
      disableElevation
    >
      {text}
    </Button>
  )
}
