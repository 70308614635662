import Chip from "@material-ui/core/Chip";
import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import {useHistory} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";


export default function CardGrid(props) {
  let history = useHistory();

  const cardColumns = [
    {
      field: "question",
      headerName: "Question",
      width: 400,
      renderCell: (params) => <Tooltip title={getCardText(params.value)} placement="top-start"><div>{getQuestion(params.value)}</div></Tooltip>
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
      renderCell: (params) => params.value
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 600,
      renderCell: (params) => params.value.sort().map(tag => <Chip style={{margin: 5}} label={tag}/>)
    },
    {
      field: "script",
      headerName: "Card Type",
      width: 200,
      renderCell: (params) => cardType(params.value)
    }
  ]

  return <DataGrid
    selectionModel={props.selectionModel}
    onSelectionModelChange={props.onSelectionModelChange}
    rows={props.cards.map(card => {return {...card, 'id': card._id, 'question': card.script, 'source': getCardSource(card.tags)}})}
    columns={cardColumns}
    checkboxSelection
    disableSelectionOnClick
    onRowClick={(params, event) => window.open(`/edit_card/${params.id}`, "_blank")}
  />
}

function getCardSource(cards) {
  const card = cards.find(card => card.startsWith("SOURCE: "))
  return card ? card.substring("SOURCE: ".length) : "User Created"
}

function getQuestion(script) {
  script = script.replace(/\s+/g, " ").trim()
  const TYPES = ["clozeDeletion", "longAnswer", "shortAnswer", "multipleChoice", "checkBoxes"]
  for (const type of TYPES) {
    if (script.startsWith(`() => ${type}`)) {
      script = script.substring(`() => ${type}`.length)
      const regex = /(["'`])(.*)\1/
      const match = script.match(regex)
      if (match) {
        return match[2]
      } else {
        return "Unable to parse question"
      }
    }
  }
  const regex = /({\s*question:\s*"(.*)"\s*,)/
  const match = script.match(regex)
  return match ? match[2] : "JS Question"
}

function cardType(script) {
  if (script.startsWith("() => clozeDeletion")) {
    return "Cloze"
  }
  if (script.startsWith("() => longAnswer")) {
    return "Long Answer"
  }
  if (script.startsWith("() => shortAnswer")) {
    return "Short Answer"
  }
  if (script.startsWith("() => multipleChoice")) {
    return "Multiple Choice"
  }
  if (script.startsWith("() => checkBoxes")) {
    return "Check Boxes"
  }
  return <pre style={{color: "red"}}>script</pre>
}

function getCardText(str) {
  if (str.includes("(\"")) {
    let newStr = str.split("(\"")[1];
    return newStr.slice(0, -2);
  }
  else if (str.includes("clozeDeletion(\`")) {
    let newStr = str.split("clozeDeletion(\`")[1];
    return newStr.slice(0, -2);
  }
  else if (str.includes("clozeDeletion\(String.raw\`")) {
    let newStr = str.split("clozeDeletion\(String.raw\`")[1];
    return newStr.slice(0, -2);
  }
  else {
    return str
  }
}

function tooltipContent(info) {
  return (
    <Tooltip title={info} placement="top-start">info</Tooltip>
  )
}