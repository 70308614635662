import React, { Fragment, useEffect, useState } from "react"
import useCallSecureApi from "../../../components/secureRequest"
import Assignment from "../../../components/Assignment"
import Button from "@material-ui/core/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"

function AssignmentsTab({
  courseId,
  course,
  assignments,
  setAssignments,
  cards,
  studentSelectionModel,
  setStudentSelectionModel,
  setTab,
  cardSelectionModel,
  setCardSelectionModel,
}) {
  const callSecureApi = useCallSecureApi()
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [canvasAssignments, setCanvasAssignments] = useState(undefined)

  useEffect(() => {
    callSecureApi(`/course/${courseId}/assignment`).then((res) => {
      setAssignments(res)
    })
  }, [courseId])

  useEffect(() => {
    callSecureApi(`/course/${courseId}/available_canvas_assignments`).then(
      (res) => {
        setCanvasAssignments(res)
        console.log("canvasAssignments: ", res)
      }
    )
  }, [course])

  const createAssignment = () =>
    callSecureApi(
      `/course/${courseId}/assignment`,
      "POST",
      {},
      {
        newAssignment: {
          name: "Untitled Assignment",
          cards: [],
          completions: [],
        },
      }
    )
      .then((res) => setAssignments((oldAssignment) => [...oldAssignment, res]))
      .catch((_) => console.log("failed to create assignment"))

  const deleteAssignment = i =>
    callSecureApi(
      `/course/${courseId}/assignment/${assignments[i]._id}`,
      "DELETE"
      )
      .then(_ => setAssignments(oldAssignments => 
        oldAssignments.filter((_, j) => j != i)
      ))
      .catch((_) => console.log("failed to delete assignment"))
    
  const setAssignment = (i) => (f) => {
    const a = assignments[i]
    setAssignments((oldAssignments) => {
      const newAssignments = [...oldAssignments]
      newAssignments[i] = f(a)
      return newAssignments
    })
  }

  return (
    <Fragment>
      {assignments.map((a, i) => (
        <Fragment key={i}>
          <div
            onClick={() =>
              setSelectedAssignment((oldState) =>
                oldState === a._id ? null : a._id
              )
            }
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                backgroundColor: "rgb(238, 238, 238)",
                padding: 20,
                marginBottom: 0,
                position: "relative"
              }}
            >
              <h1 style={{margin: 0, width: "95%"}}>{a.name}</h1>
              <FontAwesomeIcon
                style={{width: "5%", position: "absolute", right: "20px", top: "20px", height: "100%", maxHeight: "35px"}}
                icon={selectedAssignment === a._id ? faCaretUp : faCaretDown}
              />
            </div>
          </div>
          {selectedAssignment === a._id && (
            <div
              style={{
                backgroundColor: "rgb(238, 238, 238)",
                padding: 20
              }}
            >
              {/* TODO: FIX ASSIGNMENT STUDENT SELECTION MODEL */}
              <Assignment
                assignment={a}
                courseId={courseId}
                cards={cards.filter(
                  (card) =>
                    a.cards.includes(card._id) ||
                    a.randomCards.includes(card._id)
                )}
                setAssignment={setAssignment(i)}
                deleteAssignment={() => deleteAssignment(i)}
                studentSelectionModel={studentSelectionModel}
                setStudentSelectionModel={setStudentSelectionModel}
                onGo={() => setTab("students")}
                cardSelectionModel={cardSelectionModel}
                setCardSelectionModel={setCardSelectionModel}
                canvasAssignments={canvasAssignments}
                onSave={async () => {
                  callSecureApi(
                    `/course/${courseId}/assignment/${assignments[i]._id}`,
                    "PUT",
                    {},
                    { newAssignment: a }
                  )
                    .then((_) => {})
                    .catch((_) => {})
                }}
              />
            </div>
          )}
          <br />
        </Fragment>
      ))}
      <br />
      <Button
        style={{ marginBottom: 20 }}
        variant="contained"
        color="primary"
        onClick={createAssignment}
      >
        Create Assignment
      </Button>
    </Fragment>
  )
}

export default AssignmentsTab
