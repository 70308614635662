import React, {Fragment, useEffect, useState} from "react";
import QuestionAnnotationInterface from "../components/QuestionAnnotationInterface"
import useCallSecureApi from "../components/secureRequest";
import { cardNeedsReview } from "../utilities/cardFilters"

export default function QuestionAnnotation(props) {
  const callSecureApi = useCallSecureApi()
  const [source, setSource] = useState({})
  const [courseData, setCourseData] = useState({assignments: []})
  const [assignments, setAssignments] = useState([])
  const [questions, setQuestions] = useState([])
  const file = true

  const {courseId, sourceId} = props.match.params

  const rewrite = (question, answer, instructions) => callSecureApi(`/question/rewrite`, "POST", {}, {question, answer, instructions})

  useEffect(() => {
    callSecureApi(`/course/${courseId}/source/${sourceId}`, "GET").then(setSource)
    callSecureApi(`/course/${courseId}`).then(setCourseData)
  }, [])

  useEffect(() => {
    // TODO: Set up loading bar to indicate data is loading.
    if (courseData.cards) {
      setQuestions(
        courseData.cards
        .filter((card) => cardNeedsReview(card, sourceId))
        .sort((a, b) => getScore(b) - getScore(a)))
    }
    callSecureApi(`/course/${courseId}/assignment/`).then(setAssignments)
  }, [courseData, sourceId])

  const setAssignment = (assignmentId, f) => {
    const i = assignments.findIndex(assignment => assignment._id === assignmentId)
    const a = assignments[i]
    callSecureApi(`/course/${courseId}/assignment/${assignmentId}`, "PUT", {}, {newAssignment: f(a)}).then(res => {
      setAssignments(oldAssignments => {
        const newAssignments = [...oldAssignments]
        newAssignments[i] = res
        return newAssignments
      })
    })
  }
  const updateAssignment = (assignmentId, newFields) => setAssignment(assignmentId, assignment => {
    return {...assignment, ...newFields}
  })
  const addCard = (cardId) => {
    if (source.assignment) {
      const assignment = assignments.find(assignment => assignment._id === source.assignment)
      updateAssignment(source.assignment, {cards: unique([...assignment.cards, cardId])})
    }
  }

  const onSelect = (assignmentId) => {
    const newSource = { name: source.name, image: source.image, link: source.link, assignment: assignmentId}
    callSecureApi(`/course/${courseId}/source/${sourceId}`, "PUT", {}, newSource).then(setSource)
  }

  return (
    <Fragment>
      <section id="question-generation-content">
        <div className="container" style={{"width": "95%"}}>
          <div className="col-33">
            <TextbookImage
              file={file}
              source={source}
              courseName={courseData?.course?.name}
            />
          </div>
          <div className="col-66">
            <QuestionAnnotationInterface
              assignments={assignments}
              source={source}
              onSelect={onSelect}
              onRewrite={rewrite}
              questions={questions}
              setQuestions={setQuestions}
              file={file}
              onAccept={async (q, n) => {
                let newScript = `() => ({question: "${n.question}", answer: "${n.answer}"})`
                if (newScript !== q.script) {
                  newScript = `//${JSON.stringify(q.script)}\n${newScript}`
                }
                await callSecureApi(`/card/${q._id}`, "PUT", {
                  ...q,
                  script: newScript,
                  tags: [...q.tags.filter(tag => tag !== "TO REVIEW"), "ACCEPTED"]
                })
                addCard(q._id)
              }}
              onReject={async (q, n) => {
                await callSecureApi(`/card/${q._id}`, "PUT", {
                  ...q,
                  tags: [...q.tags.filter(tag => tag !== "TO REVIEW"), "REJECTED"]
                })
              }}
            />
          </div>
          <div style={{"clear": "both"}}/>
        </div>
      </section>
    </Fragment>
  )
}

function TextbookImage({file, source, courseName}) {
  return (
    <div className={"textbook-upload-button"}>
      {source.image !== undefined && source.image !== "replace_this.png" ?
      <img src={source.image} alt="textbook" style={{width: '100%', display: file ? 'block' : 'none'}}/>
      :
      <div style={{padding: "70px", fontSize: "25px"}}>
        Textbook Image Missing {courseName ? `(for ${courseName})` : ""}
      </div>
      }
    </div>
  )
}

const unique = a => Array.from(new Set(a))

const getScore = card => parseFloat(card.tags.find(tag => /SOURCE: .*/.test(tag)).split('SOURCE: ')[1])
