// TODO: Break the StreakCounter into multiple components
import React, {Fragment} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import './StreakCounter.css';

const START_DATE = '5/8/22'

// Convert a Date to a mm/dd/yy string in America/New_York time
function dateToString(dateObj) {
  const options = {
    year: '2-digit', month: '2-digit', day: '2-digit',
    timeZone: 'America/New_York',
  }
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options)
  return dateTimeFormat.format(dateObj)
}

function expandArray(array) {
  const newArray = []

  let lastDate = new Date(START_DATE)
  while (newArray.length < 15 * 7) {
    const existingData = array.find(dataObject => dataObject.date === dateToString(lastDate))
    if (existingData) {
      newArray.push({...existingData, isComplete: true})
    } else {
      newArray.push({date: dateToString(lastDate), numCards: 0, isComplete: false})
    }
    lastDate.setDate(lastDate.getDate() + 1)
  }
  return newArray
}

function toRowsOf(length, array) {
  let oldArr = [...array]
  let newArr = []
  while (oldArr.length) newArr.push(oldArr.splice(0, length));
  return newArr
}

function isBeforeToday(date) {
  return new Date(dateToString(date)) < new Date(dateToString(new Date()));
}

function isToday(date) {
  return dateToString(date) === dateToString(new Date());
}

function getStreakFrom(array) {
  return array.reduce((streak, dataObject) => {
    const date = new Date(dataObject.date)
    if (!(isBeforeToday(date) || isToday(date))) return streak
    if (!dataObject.isComplete) return 0
    return streak + 1
  }, 0)
}

export default function StreakCounter(props) {
  const square = (dataObject) => {
    let color;
    if (isBeforeToday(new Date(dataObject.date))) {
      color = dataObject.isComplete || dataObject.numCards > 0 ? `rgba(255, 86, 63, ${0.25 + dataObject.numCards/(0.75*20)})` : "rgba(0,0,0, 0.25)"
    } else if (isToday(new Date(dataObject.date))) {
      color = dataObject.isComplete || dataObject.numCards > 0  ? `rgba(255, 86, 63, ${0.25 + dataObject.numCards/(0.75*20)})` : "#fff"
    } else {
      color = `rgba(0,0,0, ${Math.random() * 0.25})`
    }
    return (
      <Tooltip
        title={dataObject.isComplete || dataObject.numCards > 0 ? `You studied ${dataObject.numCards} cards on ${dataObject.date}` : `${dataObject.date}`}>
        <div className="gridButton"
          style={{
            backgroundColor: color,
            border: isToday(new Date(dataObject.date)) ? "4px solid #000" : "none",
            marginBottom: isToday(new Date(dataObject.date)) ? "6px" : "10px",
            marginLeft: isToday(new Date(dataObject.date)) ? -4 : 0,
            marginTop: isToday(new Date(dataObject.date)) ? -4 : 0,
            // boxSizing: "border-box"
          }}
        >
        </div>
      </Tooltip>
    )
  }

  const streak = getStreakFrom(expandArray(props.reviewData))
  return (
      <Fragment>
        { streak > 0
          ? (
            <p>Your current streak is:&nbsp;
              <span style={{fontWeight: 777, fontSize: "xx-large", position: "relative", top: 3}}>
              {streak}
            </span>
            </p>
          ) : (
            <p>Complete your daily review to start a streak!</p>
          )
          }
        <div className="grid">
          {toRowsOf(7, expandArray(props.reviewData))
            .map((row) =>
              <div style={{flex: 1}}>
                {row.map(square).flat()}
              </div>
            )}
        </div>
      </Fragment>
    )
}
