import React from "react";
import ReminderSettingManager from "../components/ReminderSettingManager";
import Container from "@material-ui/core/Container";

function Settings() {
  return (
    <Container maxWidth="sm" style={{marginTop: 200}}>
      <h1>Settings</h1>
      <br/>
      <ReminderSettingManager/>
    </Container>
  )
}

export default Settings
