import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CodeEditor from "./CodeEditor";
import {TagManager} from "./TagManager";
import Button from "@material-ui/core/Button";
import CardRenderer from "../CardRenderer";
import React, {Fragment} from "react";
import Typography from "@material-ui/core/Typography";

export default function CardEditor(props) {

  const setScript = (newScript) => {
    props.setCard({...props.card, script: newScript})
  }

  const setTags = (newTags) => {
    props.setCard({...props.card, tags: newTags})
  }

  return (
    <Container style={{paddingTop:10}}>
      <Grid container>
        <Grid item xs={6} style={{paddingRight: 25}}>
          {
            props.input ?
              props.input
              :
              <Fragment>
                <CodeEditor lambda={props.card.script} onChange={setScript}/>
                <a target="_blank" rel="noreferrer" style={{textDecoration: 'none', margin: 10}} href="https://www.supermemo.com/en/archives1990-2015/articles/20rules">
                  <Typography color="textSecondary" variant="overline">
                    Article on making good cards
                  </Typography>
                </a> | <a target="_blank" rel="noreferrer" style={{textDecoration: 'none', margin: 10}} href="https://docs.google.com/spreadsheets/d/11xZoseAKs6dAfjQaBQo1cTegHncUMrOTMCDmOfYOWOo/edit?usp=sharing">
                <Typography color="textSecondary" variant="overline">
                  Ideas For Cards
                </Typography>
              </a>

                <br/>
                <TagManager tags={props.card.tags} onChange={setTags}/>
                <br/>
                <Button color="primary" variant="contained" onClick={props.saveCard}>Save</Button>
                {props.card._id && <Button color="primary" variant="contained" href={`/card/${props.card._id}/dag`}
                         style={{marginLeft: 20}}>DAG view</Button>}
              </Fragment>
          }
        </Grid>
        <Grid item xs={6} style={{textAlign: 'center', paddingLeft: 25}}>
          <div style={{display: 'flex'}}>
            <CardRenderer
              card={props.card}
              onButtonClick={() => {}}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

