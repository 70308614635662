
import ListItem from "@material-ui/core/ListItem";
import {List} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const NavBar = ({ courseId, setTab }) => {
    return (
        <List component="nav">
          <ListItem>
            <Button onClick={() => {
              return setTab("initial")
            }}>
              Home
            </Button>
          </ListItem>
          <ListItem>
            <Button onClick={() => {
              return setTab("cards")
            }}>
              Question Bank
            </Button>
          </ListItem>
          <ListItem>
            <Button onClick={() => {
              return setTab("roster")
            }}>
              Roster
            </Button>
          </ListItem>
          <ListItem>
            <Button onClick={() => {
              return setTab("assignments")
            }}>
              Assignments
            </Button>
          </ListItem>
          <ListItem disabled>
            <Button href={`/course/${courseId}/settings`}>
              Course Settings
            </Button>
          </ListItem>
        </List>
    )
}
export default NavBar;