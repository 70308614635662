import Editor from "react-simple-code-editor";
import {highlight, languages} from "prismjs/components/prism-core";
import Alert from "@material-ui/lab/Alert";
import React, {Fragment} from "react";

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "../../prismjs/prism.css"

export default function CodeEditor(props) {
  return(
    <Fragment>
      <Editor
        value={props.lambda}
        onValueChange={code => props.onChange(code)}
        highlight={code => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          backgroundColor: '#272822',
          caretColor: 'white',
          color: 'white'
        }}
      />
      <div>
        {
          isValid(props.lambda) ?
            <Alert severity="success">
              <b>The card evaluates successfully</b>
              <br/>
              Note: this doesn't include runtime errors, only evaluation
            </Alert>
            :
            <Alert severity="error">
              {getError(props.lambda)}
            </Alert>
        }
      </div>
    </Fragment>
    )
}

function isValid(code) {
  try {
    eval(code)
    return true
  } catch {
    return false
  }
}

function getError(code) {
  try {
    eval(code)
    return false
  } catch(err) {
    console.log(err)
    return err.toString()
  }
}
