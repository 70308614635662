import React, {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faCheck, faPencil, faWandMagicSparkles, faXmark} from '@fortawesome/free-solid-svg-icons'
import './styles.css';
import prelude from "../CardRenderer/prelude";
import MarkdownRenderer from "../MarkdownRenderer";

export default function QuestionPreview({card, onAccept, onReject, onRewrite}) {
  const [questionText, setQuestionText] = useState({question: "", answer: ""})
  const [editing, setEditing] = useState(false)
  const [showContext, setShowContext] = useState(false)
  const [instructions, setInstructions] = useState(undefined)
  const [showInstructions, setShowInstructions] = useState(false)
  const [response, setResponse] = useState(undefined)

  useEffect(() => {
    const expr = prelude + "const f = " + card.script + "; f();";
    try {
      const evaluated = eval(expr)
      setQuestionText(evaluated)
    } catch {
      console.log(`ERROR: card ${card._id} was not able to be rendered`)
    }
  }, [card])

  const toggleEditing = () => setEditing(old => !old)
  const toggleShowContext = () => setShowContext(old => !old)
  const toggleShowInstructions = () => setShowInstructions(old => !old)

  return (
    <div className="generated-question" style={{paddingBottom: 10}}>
      {!editing
        ? <div>
          <MarkdownRenderer>
            {"Q: " + questionText.question}
          </MarkdownRenderer>
          <MarkdownRenderer>
            {"A: " + questionText.answer}
          </MarkdownRenderer>
          {showInstructions &&
            <div>
              <textarea
                placeholder={"Tell me how to rewrite the question! For example: 'turn this into a coding question'"}
                value={instructions}
                style={{width: "80%"}}
                rows={3}
                onChange={(e) => setInstructions(e.target.value)}
              />
              <div className={"button"} onClick={() => {
                onRewrite(questionText.question, questionText.answer, instructions).then(res => setResponse(res))
              }}>Rewrite</div>

              {response && <div>
                <MarkdownRenderer>
                  {"Q: " + response.question}
                </MarkdownRenderer>
                <MarkdownRenderer>
                  {"A: " + response.answer}
                </MarkdownRenderer>
                <div className={"button"} onClick={() => {
                  setQuestionText(response)
                  setResponse(undefined)
                }}>
                  Keep This
                </div>
                <div className={"button"} onClick={() => setResponse(undefined)}>
                  Discard This
                </div>
              </div>}

            </div>
          }
        </div>
        : <div>
          <p>Q: <textarea
            value={questionText.question}
            style={{width: "100%"}}
            rows={2}
            onChange={(e) => setQuestionText(oldQuestion => ({...oldQuestion, question: e.target.value}))}
          /></p>
          <p>A: <textarea
            value={questionText.answer}
            style={{width: "100%"}}
            rows={2}
            onChange={(e) => setQuestionText(oldQuestion => ({...oldQuestion, answer: e.target.value}))}
          /></p>
        </div>
      }
      <FontAwesomeIcon
        icon={faAlignLeft} style={{padding: 4, float: 'right', marginTop: -20, marginRight: -20}}
        className={"clickable"} onClick={toggleShowContext}
      />
      {showContext
        ? <div
          style={{
            backgroundColor: "#eeeeee",
            padding: 5
          }}><b>This Question Was Generated From The Following Context:</b>
          <br/><br/><MarkdownRenderer>{getComment(card.script)}</MarkdownRenderer>
        </div>
        // ? getComment(card.script)
        : undefined
      }

      <div className="controls">
        <FontAwesomeIcon
          icon={faCheck} style={{padding: 4}} className={"clickable"}
          onClick={() => onAccept(card, questionText)}
        />
        <FontAwesomeIcon
          icon={faXmark} style={{padding: 4}} className={"clickable"}
          onClick={() => onReject(card, questionText)}
        />
        <FontAwesomeIcon
          icon={faPencil} style={{padding: 4}} className={"clickable"}
          onClick={toggleEditing}
        />
        <FontAwesomeIcon
          icon={faWandMagicSparkles} style={{padding: 4}} className={"clickable"}
          onClick={toggleShowInstructions}
        />
      </div>
    </div>
  )
}

function getComment(script) {
  return script.split("\n")[0].slice(3).replaceAll("\\n", "")
}
