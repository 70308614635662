import React, {Fragment, useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import useCallSecureApi from "../../components/secureRequest"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import FlipToFrontIcon from '@material-ui/icons/FlipToFront'
import {Badge} from "@material-ui/core"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import MaterialLink from '@material-ui/core/Link'
import Button from "@material-ui/core/Button"
import QueueIcon from '@material-ui/icons/Queue'
import UpdateIcon from '@material-ui/icons/Update'
import Tooltip from '@material-ui/core/Tooltip'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CardGrid from "../../components/CardGrid"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import SearchParser from "../../components/SearchParser"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import NavBar from './NavBar'
import HomeTab from './tabs/HomeTab'
import RosterTab from './tabs/RosterTab'
import AssignmentsTab from './tabs/AssignmentsTab'

// TODO: Refactor this into smaller components
export default function Course(props) {
  const [course, setCourse] = useState({})

  const [cards, setCards] = useState([])
  const [cardFilter, setCardFilter] = useState(() => (_) => true)
  const [cardSelectionModel, setCardSelectionModel] = useState([])

  const [students, setStudents] = useState([])
  const [staff, setStaff] = useState([])
  const [studentSelectionModel, setStudentSelectionModel] = useState([])
  const [staffSelectionModel, setStaffSelectionModel] = useState([])

  const [sources, setSources] = useState([])

  const [tab, setTab] = useState("initial")
  const [selectedDate, setSelectedDate] = useState(getCurrentDate)
  const [selectedTime, setSelectedTime] = useState(getCurrentTime())
  const [onlyOnce, setOnlyOnce] = useState(true)
  const toggleOnlyOnce = () => setOnlyOnce(oldOnlyOnce => !oldOnlyOnce)
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const callSecureApi = useCallSecureApi()

  // This can be kind of slow -- might want a loading bar for this page as well
  useEffect(() => {
    callSecureApi(`/course/${props.match.params.id}`).then(res => {
      setCourse(res.course)
      setCards(res.cards)
      setStudents(res.students)
      setStaff(res.staff)
      setSources(res.sources)
    })
  }, [props.match.params.id])

  useEffect(() => {
    // pre-assigned tab
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    if (tab) setTab(tab)

    document.body.style.backgroundColor = '#fff'
  }, [])

  const updateCourseField = (field) => (value) => {
    setCourse(oldCourse => ({...oldCourse, [field]: value}))
  }

  const updateCourse = () => {
    const {name, instructorName, description} = course
    callSecureApi(`/course/${props.match.params.id}`, "PUT", {}, {name, instructorName, description})
  }

  const schedule = () => {
    callSecureApi(`/schedule`, "POST", {}, {
      courseId: props.match.params.id,
      cardIds: cardSelectionModel,
      studentIds: studentSelectionModel,
      // Using EDT will fail after daylight savings, and for anyone not on the east coast
      // TODO: Replace EDT with correct timezone
      date: `${selectedDate} ${selectedTime} EDT`,
      onlyOnce: onlyOnce
    })
  }

  const [assignments, setAssignments] = useState([])

  return (
    <Grid container style={{marginTop: 100, marginBottom: 130}}>
      <Grid item xs={3} style={{padding: 50}}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
          <Link to="/courses">
            <MaterialLink>
              Dashboard
            </MaterialLink>
          </Link>
          <Typography color="textPrimary">
            {course.name}
          </Typography>
        </Breadcrumbs>
        <NavBar courseId={props.match.params.id} setTab={setTab}/>
        <br/><br/>
        Students can join your course with this code: 
        <br/>
        <Tooltip enterDelay={10} title="Click to Copy">
          <TextField
            id="standard-start-adornment"
            value={course.slug}
            InputProps={{
              endAdornment:
              <InputAdornment 
              position="start"
              style={{cursor: "pointer"}}
              >
                  <FlipToFrontIcon/>
              </InputAdornment>
            }}
            inputProps={{style: {cursor: "pointer"}}}
            onClick={() => {
              navigator.clipboard.writeText(course.slug)
            }}
            disabled
          />
        </Tooltip>
      </Grid>
      <Grid item xs={9} style={{padding: 10, height: 'calc(100vh - 200px)'}}>
        {tab === 'initial' &&
          <HomeTab
            course={course}
            students={students}
            cards={cards}
            assignments={assignments}
            sources={sources}
            updateCourseField={updateCourseField}
            updateCourse={updateCourse}
          />
        }
        {tab === 'cards' &&
          <Fragment>
            <SearchParser elements={cards} setFilter={setCardFilter} default={'{"Tags": {"$not": "REJECTED"}}'}/>
            <CardGrid
              cards={cards.filter(cardFilter)}
              selectionModel={cardSelectionModel}
              onSelectionModelChange={(newSelectionModel) => setCardSelectionModel(newSelectionModel)}
            />
          </Fragment>
        }
        {tab === 'roster' &&
          <RosterTab
            courseId={props.match.params.id}
            students={students}
            setStudents={setStudents}
            staff={staff}
            setStaff={setStaff}
            studentSelectionModel={studentSelectionModel}
            setStudentSelectionModel={setStudentSelectionModel}
            staffSelectionModel={staffSelectionModel}
            setStaffSelectionModel={setStaffSelectionModel}
          />
        }
        {tab === 'assignments' &&
          <AssignmentsTab 
          courseId={props.match.params.id}
          course={course}
          assignments={assignments} 
          setAssignments={setAssignments}
          cards={cards}
          studentSelectionModel={studentSelectionModel}
          setStudentSelectionModel={setStudentSelectionModel}
          setTab={setTab}
          cardSelectionModel={cardSelectionModel}
          setCardSelectionModel={setCardSelectionModel}
          />
        }
        <div>
          {
            tab === "cards"
              ? <>
                <Button
                  variant="contained"
                  color="primary"
                  href={`/course/${props.match.params.id}/create_card`}
                  style={{marginTop: 10, marginRight: 20}}
                >
                  <QueueIcon/>Create Cards
                </Button>
              </>
              : undefined
          }
          {/* {
            tab === "students"
              ? <Button
                variant="contained"
                color="primary"
                href={``}  // How do we handle this properly? Do we have a better way of handling this? CSV addition, etc.
                style={{marginTop: 10, marginRight: 10}}
              >
                <PersonAddIcon/>Add Students From CSV
              </Button>
              : undefined
          } */}
          {(tab === "cards" || tab === "roster") && <Button
            variant="contained"
            color="secondary"
            style={{marginTop: 10}}
            disabled={cardSelectionModel.length === 0 || studentSelectionModel.filter(u => u.isStudent).length === 0}
            onClick={handleClickOpen}
          >
            <UpdateIcon/>Schedule Cards
          </Button>}
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Schedule cards</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>
                  {/* TODO: ADD FILTER */}
                  Assign {studentSelectionModel.length} students {cardSelectionModel.length} cards each
                  at
                </p>
              </DialogContentText>
              <TextField
                id="date"
                type="date"
                value={selectedDate}
                onChange={(event) => setSelectedDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="time"
                type="time"
                value={selectedTime}
                onChange={(event) => setSelectedTime(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={!onlyOnce} onChange={toggleOnlyOnce} name="onlyOnce"/>}
                label="Allow duplicate cards to be added to students"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  schedule()
                  handleClose()
                }}
                color="primary">
                Schedule cards
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  )
}

// const PointyTextField = styled(TextField)({cursor: "pointer", backgroundColor: "green"})

function getCurrentDate() {
  const date = new Date()
  return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? "" : "0"}${date.getMonth() + 1}-${date.getDate()}`
}

function getCurrentTime() {
  const date = new Date()
  return `${date.getHours()}:${date.getMinutes()}`
}
