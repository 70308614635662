import { useEffect, useState} from 'react'
import useCallSecureApi from "../../components/secureRequest"
import AssignmentSelection from './AssignmentSelection'
import CourseSelection from './CourseSelection'

export default function Lti() {
    const callSecureApi = useCallSecureApi()

    const [selectedCourse, setSelectedCourse] = useState(null)
    const [assignments, setAssignments] = useState([])
    const urlParams = new URLSearchParams(window.location.search);

    const assignCourse = async (course) => {
        await updateCourse(course._id, false)

        // add to url params
        urlParams.set('courseId', course._id)
        urlParams.set('courseName', course.name)
        window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams}`);

        setSelectedCourse(course)
    }

    const unassignCourse = async () => {
        await updateCourse(selectedCourse._id, true)

        // remove from url params
        urlParams.delete('courseId')
        urlParams.delete('courseName')
        window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams}`);

        setSelectedCourse(null)
    }

    const updateCourse = async (courseId, reset) => {
        const body = reset ? {} : {
            ltiPlatform: {
                url: urlParams.get('platformUrl'),
                clientId: urlParams.get('clientId'),
                deploymentId: urlParams.get('deploymentId'),
            },
            ltiContextId: urlParams.get('contextId'),
            ltiMembershipsUrl: urlParams.get('membershipsUrl'),
            ltiLineItemsUrl: urlParams.get('lineItemsUrl')
        }
        await callSecureApi(`/course/${courseId}/lti`, "PATCH", {}, body)
    }

    useEffect(() => {
      if (!urlParams.get("ltik")) {
        window.location.href = "/"
      }
      if (urlParams.get('courseId') && urlParams.get('courseName')) {
        setSelectedCourse({
          _id: urlParams.get('courseId'),
          name: urlParams.get('courseName')
        })
      }
    }, [])

    useEffect(() => {
        if (selectedCourse) {
            callSecureApi(`/course/${selectedCourse._id}/assignment`)
                .then(res => setAssignments(res))
        }
    }, [selectedCourse])

    return (
        selectedCourse ? 
            <AssignmentSelection 
                courseId={selectedCourse._id}
                courseName={selectedCourse.name}
                assignments={assignments}
                unassignCourse={unassignCourse}
            /> : 
            <CourseSelection 
                assignCourse={assignCourse}
            />
    )
}