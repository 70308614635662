import React, {Fragment, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faArrowUpLong,
  faCheck,
  faFloppyDisk,
  faGear,
  faGraduationCap,
  faLayerGroup,
  faPencil,
  faPlus,
  faSquarePlus,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import CardRenderer from "./CardRenderer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip"
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

// TODO: Need to display this more cleanly
// TODO: Need to display students' answers
// TODO: Need more intuitive flow to add users or cards (don't require switching between screens via side-panel)

// TODO: figure out if it's sufficient to pass in the assignment object or if we need setters
export default function Assignment(
  {
    assignment, cards, setAssignment, deleteAssignment, onSave, courseId,
    studentSelectionModel, setStudentSelectionModel, onGo,
    cardSelectionModel, setCardSelectionModel, canvasAssignments,
    ...props
  }
) {
  const updateAssignment = (newFields) => setAssignment(assignment => {
    return {...assignment, ...newFields}
  })
  const handleNameChange = (e) => updateAssignment({name: e.target.value})
  const handleReleaseDateChange = (e) => updateAssignment({releaseDate: e.target.value == "" ? null : e.target.value})
  const handleDueDateChange = (e) => updateAssignment({dueDate: e.target.value == "" ? null : e.target.value})

  const addSelectedStudents = () =>
    updateAssignment({students: unique([...assignment.students, ...studentSelectionModel])})
  const removeSelectedStudents = () =>
    updateAssignment({students: assignment.students.filter(s => !studentSelectionModel.includes(s))})

  const removeSelectedCards = () =>
    updateAssignment({cards: assignment.cards.filter(c => !cardSelectionModel.includes(c))})

  const changeCanvasAssignment = (e) => {
    updateAssignment({canvasAssignment: e.target.value})
  }

  const removeCard = (cardId) =>
    updateAssignment({cards: assignment.cards.filter(c => c !== cardId)})

  const swapCards = (i, j) => {
    const newCards = [...assignment.cards];
    [newCards[i], newCards[j]] = [newCards[j], newCards[i]]
    updateAssignment({cards: newCards})
  }

  const dateToString = (date, defaultStr) => date == null ? defaultStr : convertDateFormat(new Date(date))

  return (
    <div>
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: 5
      }}>
        <div style={{flex: "1 0 45%", width: 0}}>
            <EditableButtonWidget 
            value={assignment.name} 
            setValue={handleNameChange}
            inputType="text"
            buttonText="Edit Name"
            />
        </div>
        <div style={{flex: "1 0 45%", width: 0}}>
          <SaveButton 
          onSave={onSave}/>
        </div>
        <div style={{flex: "1 0 45%", width: 0}}>
            <EditableButtonWidget 
            value={dateToString(assignment.releaseDate, "")} 
            setValue={handleReleaseDateChange}
            inputType="date"
            buttonText={"Release Date: " + dateToString(assignment.releaseDate, "N/A")}
            />
        </div>
        <div style={{flex: "1 0 45%", width: 0}}>
            <EditableButtonWidget 
            value={dateToString(assignment.dueDate, "")} 
            setValue={handleDueDateChange}
            inputType="date"
            buttonText={"Due Date: " + dateToString(assignment.dueDate, "N/A")}
            />
        </div>
        {/*<div className="button" style={{float: 'right'}}>*/}
        {/*  <FontAwesomeIcon icon={faEye} style={{marginRight: 10}}/>Preview*/}
        {/*</div>*/}
      </div>
      <div style={{backgroundColor: "#eeeeee", width: "100%", paddingBottom: 15}}>
        {assignment.cards.map((cardId, i) =>
          <CardDisplay
            card={cards.find(card => card._id === cardId)}
            onRemove={() => removeCard(cardId)}
            onUp={() => swapCards(i, i - 1)}
            onDown={() => swapCards(i, i + 1)}
            isFirst={i === 0}
            isLast={i === assignment.cards.length - 1}
          />
        )}
        <BottomBar
          assignment={assignment} updateAssignment={updateAssignment} cards={cards}
          cardSelectionModel={cardSelectionModel} setStudentSelectionModel={setStudentSelectionModel}
          studentSelectionModel={studentSelectionModel} onGo={onGo} courseId={courseId}
          deleteAssignment={deleteAssignment}
        />
      </div>
    </div>
  )
}

const unique = a => Array.from(new Set(a))
const convertDateFormat = d => d.toISOString().split('T')[0]

// We should probably make a more general component which takes innerInputProps and innerTypographyProps
function EditableButtonWidget({value, setValue, inputType, buttonText}) {
  const [editName, setEditName] = useState(false)
  if (editName) {
    return (
      <Fragment>
        <input
          type={inputType}
          value={value}
          style={{
            marginBottom: 10,
            display: "inline-block",
            padding: 10, 
            fontSize: 20, 
            width: "90%", 
            boxSizing: "border-box"
          }} 
          onChange={setValue}
        />
        <FontAwesomeIcon
          icon={faCheck} onClick={() => setEditName(x => !x)}
          style={{
            display: "inline-block",
            width: "10%",
            height: "100%",
            maxHeight: "2em",
            padding: 5,
            boxSizing: "border-box",
            verticalAlign: "middle",
            cursor: "pointer"
            }} />
      </Fragment>
    )
  }

  return (
    <div
      className="button"
      style={{margin: 0}}
      onClick={() => setEditName(x => !x)}>
        <FontAwesomeIcon icon={faPencil} style={{marginRight: 10}}/>
        {buttonText}
    </div>
  )
}

function CardDisplay({unordered = false, ...props}) {
  return (
    <div className="some-panel">
      {!unordered &&
        <CardMovementControls onUp={props.onUp} onDown={props.onDown} isFirst={props.isFirst} isLast={props.isLast}/>}
      <div className="compound-button quiz-question">
        <CardRenderer
          card={props.card}
          onButtonClick={() => {
          }}
          hideRight
          hideFeedback
        />
        <div className="controls">
          <FontAwesomeIcon
            icon={faXmark} style={{padding: 4}} className={"clickable"}
            onClick={props.onRemove}
          />
          <FontAwesomeIcon
            icon={faPencil} style={{padding: 4}} className={"clickable"}
            onClick={() => window.open(`/edit_card/${props.card._id}`)}
          />
        </div>
      </div>
    </div>
  )
}

function CardMovementControls(props) {
  return (
    <div className="up-down-navigation">
      {!props.isFirst && <div style={{position: 'absolute', top: 0, left: 0, width: 30}} onClick={props.onUp}>
        <ArrowWithBoxes direction="up" order="large-top"/>
      </div>
      }
      {!props.isLast && <div style={{position: 'absolute', bottom: 0, left: 0, width: 30}} onClick={props.onDown}>
        <ArrowWithBoxes direction="down" order="small-top"/>
      </div>}
    </div>
  )
}

function ArrowWithBoxes({direction, order}) {
  const Arrow = () => direction === "up"
    ? <div style={{float: 'left', width: 15, transform: "translate(-8px,0px)"}}>
      <FontAwesomeIcon icon={faArrowUpLong} style={{fontSize: 30}}/>
    </div>
    : <div style={{float: 'left', width: 15, transform: "translate(-8px,0px)"}}>
      <FontAwesomeIcon icon={faArrowDownLong} style={{fontSize: 30}}/>
    </div>

  const sizes = order === "small-top"
    ? [10, 20]
    : [20, 10]

  const Box = ({size, isFirst = false}) =>
    <div className="square" style={{width: size, height: size, marginBottom: isFirst ? 5 : 0}}/>

  const Boxes = () =>
    <div style={{float: 'left', width: 15}}>
      {sizes.map((x, i) => <Box size={x} isFirst={i === 0}/>)}
    </div>

  return (
    <div>
      <Arrow/>
      <Boxes/>
      <div style={{clear: 'both'}}/>
    </div>
  )
}

function BottomBar(
  {
    assignment, updateAssignment, courseId,
    cards, cardSelectionModel,
    studentSelectionModel, setStudentSelectionModel, onGo,
    deleteAssignment
  }
) {
  const removeRandomCard = (cardId) =>
    updateAssignment({randomCards: assignment.randomCards.filter(c => c !== cardId)})
  const addSelectedRandomCards = () =>
    updateAssignment({randomCards: unique([...(assignment.randomCards ? assignment.randomCards : []), ...cardSelectionModel])})
  const removeSelectedRandomCards = () =>
    updateAssignment({randomCards: assignment.randomCards.filter(c => !cardSelectionModel.includes(c))})
  const addSelectedCards = () =>
    updateAssignment({cards: unique([...assignment.cards, ...cardSelectionModel])})

  return (
    <div className="bottom-bar">
      <div>
        <div
          className="button"
          onClick={() => window.location = `/course/${courseId}/upload?assignment=${assignment._id}`}
          style={{marginRight: 0}}
        >
          <FontAwesomeIcon
            icon={faLayerGroup} style={{marginRight: 5}}/>Generate Questions
        </div>
        <Tooltip 
        title={
            <div
            style={{
              lineHeight: 1.5,
              fontSize: 15,
              padding: 5
            }}>
              Select questions from the question bank and hit this button to add those questions to this assignment.
            </div>
          }>
          <div
            className="button"
            onClick={addSelectedCards}
            style={{marginRight: 0}}
          >
            <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}}/>
            Add Existing Questions
          </div>
        </Tooltip>
        <div className="button"
             onClick={() => window.location = `/course/${courseId}/create_card?assignment=${assignment._id}`}
             style={{marginRight: 0}}
          >
          <FontAwesomeIcon icon={faSquarePlus} style={{marginRight: 5}}/>
          New Question
        </div>
      </div>
      <br/>
      <FontAwesomeIcon icon={faGraduationCap} style={{fontSize: 30, marginBottom: -5}}/>
      <span style={{fontWeight: 900}}>
        {assignment.students.length} |
        <Tooltip 
        title={
            <div
            style={{
              lineHeight: 1.5,
              fontSize: 15,
              padding: 5
            }}>
              Select students from the roster and hit this button to assign those students to this assignment.
            </div>
          }>
          <Button onClick={() => updateAssignment({students: unique(studentSelectionModel)})}>
            Assign Students
          </Button>
        </Tooltip>
      </span>
      <div style={{clear: 'both'}}/>
      <div>
        <Accordion elevation={0} style={{backgroundColor: "none"}}>
          <AccordionSummary style={{backgroundColor: "none"}} expandIcon={<ExpandMoreIcon/>}>
            <FontAwesomeIcon icon={faGear} style={{marginRight: 10}}/>
            Advanced Settings
          </AccordionSummary>
          <AccordionDetails>
            <div style={{backgroundColor: "none", display: "flex", flexDirection: "column"}}>
              <div style={{marginBottom: 20}}>
                Query:
                <TextField
                  value={assignment.studentQuery ? assignment.studentQuery : "{}"}
                  onChange={(e) => updateAssignment({studentQuery: e.target.value})}
                  style={{marginTop: -5, marginLeft: 5}}
                />
              </div>
              <div style={{marginBottom: 20}}>
                Random Cards Shown:
                <TextField
                  type="number"
                  value={assignment.numRandomCards}
                  onChange={(e) => updateAssignment({numRandomCards: e.target.value})}
                  style={{marginTop: -5, marginLeft: 5}}
                />
              </div>
              <div style={{marginBottom: 20}}>
                Random Cards:
                <Button onClick={addSelectedRandomCards}>
                  Add Selected Cards As Random Cards
                </Button>
                <Button onClick={removeSelectedRandomCards}>
                  Remove Selected Cards As Random Cards
                </Button>
                {assignment.randomCards.map((cardId, i) =>
                  <CardDisplay
                    card={cards.find(card => card._id === cardId)}
                    onRemove={() => removeRandomCard(cardId)}
                    unordered
                  />
                )}
              </div>
              <div>
                Students:
                <ManageStudentsButton 
                  setStudentSelectionModel={setStudentSelectionModel} 
                  students={assignment.students} 
                  onGo={onGo}/>              
              </div>
              <div>
                <br/>
                <Button onClick={deleteAssignment}
                variant="outlined"
                color="secondary"
                >
                  Delete Assignment
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

function SaveButton({onSave}) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div
      className="button"
      style={{margin: 0}}
      onClick={() =>
        onSave()
          .then(_ => {
            setMessage('Assignment saved successfully')
            setOpen(true)
          })
          .catch(_ => {
            setMessage('There was an error saving your assignment. Try again in a bit.')
            setOpen(true)
          })
      }
    >
      <FontAwesomeIcon icon={faFloppyDisk} style={{marginRight: 10}}/>Save
      <SaveSnackbar message={message} onClose={handleClose} open={open}/>
    </div>
  )
}

function SaveSnackbar({open, onClose, message}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      message={message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </React.Fragment>
      }
    />
  )
}

function ManageStudentsButton({setStudentSelectionModel, students, onGo}) {
  const [open, setOpen] = useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <Fragment>
      <Button 
      onClick={() => {
        setOpen(true)
        setStudentSelectionModel(students)
      }}>
        Manage Students
      </Button>
      <ManageStudentSnackbar open={open} onClose={handleClose} onGo={onGo}/>
    </Fragment>
  )
}

function ManageStudentSnackbar({open, onClose, onGo}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
      message="The students assigned to this assignment have been selected on the Students tab of this page. Go to that tab, select the students you want to assign this assignment to, then come back to this page and click the assign students button."
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={() => {
            onClose()
            onGo()
          }}>
            Go
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </React.Fragment>
      }
    />
  )
}
