import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";

export function TagManager(props) {
  const [typedTag, setTypedTag] = useState("");

  const handleDelete = (tagToDelete) => props.onChange(props.tags.filter(tag => tag !== tagToDelete))
  const handleAdd = (tagToAdd) => props.onChange(props.tags.concat(tagToAdd))

  return(
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Tags
        </Typography>
        {props.tags.map((tag, i) => {
          return (
            <Chip
              style={{margin: 5}}
              key={i}
              label={tag}
              onDelete={() => handleDelete(tag)}
            />
          )
        })}
        <br/>
        <TextField
          value={typedTag}
          placeholder='add tag'
          onChange={(e) => setTypedTag(e.target.value)}
          onKeyDown={(e) => {
            if(e.keyCode === 13){
              e.preventDefault();
              handleAdd(typedTag)
              setTypedTag("")
            }
          }}
        />
      </CardContent>
    </Card>
  )
}
